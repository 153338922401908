import { useState } from "react";
import { BsTrashFill } from "react-icons/bs";
import { ModalSucesso } from "../../shared/ModalSucesso/ModalSucesso";
import PageLayout from "../../shared/PageLayout/PageLayout";
import Title from "../../shared/Title/Title";
import { BtnIcon, ContainerCardList, ContainerInput, ContainerValor, InputDiv, InputText, LabelAdicional, LabelInput, LabelInput1, SubmitArea, SubmitButton, ValorCard, ValorCardIcons, ValorCardInfos, ValorCardLine, ValorTitle } from "./_cadastrarrecursos";

const CadastrarRecursos = () => {
    // usestate para controlar o modal 
    const [incluido, setIncluido] = useState(false); 
    const [excluido, setExcluido] = useState(false);
    return (
        <PageLayout>
            <Title 
            titulo="Cadastrar Recursos" 
            subtitulo="Cadastrar Recursos para atividades" 
            />
            <ContainerInput onSubmit={(e) => {e.preventDefault(); setIncluido(true);}}>
                <InputDiv>
                    <LabelInput>
                        Nome do Recurso:
                        <InputText type="text" placeholder="Ex.: Estrutura" />
                    </LabelInput>
                </InputDiv>
                <SubmitArea>
                    <SubmitButton type="submit">Incluir</SubmitButton>
                </SubmitArea>
            </ContainerInput>
            <ContainerValor>
                <ValorTitle>Planos Configurados</ValorTitle>
                <ContainerCardList>
                    <ValorCard>
                        <ValorCardInfos>
                            <strong>Nome do Recurso:</strong>
                            Estrutura
                        </ValorCardInfos>
                        <ValorCardIcons>
                            <BtnIcon onClick={(e) => {e.preventDefault(); setExcluido(true);}}>
                                <BsTrashFill/>
                            </BtnIcon>
                        </ValorCardIcons>
                    </ValorCard>
                    <ValorCard>
                        <ValorCardInfos>
                            <strong>Nome do Recurso:</strong>
                            Engenharia
                        </ValorCardInfos>
                        <ValorCardIcons>
                            <BtnIcon>
                                <BsTrashFill/>
                            </BtnIcon>
                        </ValorCardIcons>
                    </ValorCard>
                    <ValorCard>
                        <ValorCardInfos>
                            <strong>Nome do Recurso:</strong>
                            Compras
                        </ValorCardInfos>
                        <ValorCardIcons>
                            <BtnIcon>
                                <BsTrashFill/>
                            </BtnIcon>
                        </ValorCardIcons>
                    </ValorCard>
                    
                </ContainerCardList>
            </ContainerValor>
        
            <ModalSucesso 
            content="Incluída com sucesso!" 
            setState={setIncluido} 
            state={incluido} 
            />
            <ModalSucesso 
            content="Excluído com sucesso!" 
            setState={setExcluido} 
            state={excluido} 
            />
        </PageLayout>
    )
}
export default CadastrarRecursos;