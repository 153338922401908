import PageLayout from "../../shared/PageLayout/PageLayout"
import Title from "../../shared/Title/Title";
import {
    ContainerValor,
    Header,
    HeaderLine,
    HeaderValor,
    HeaderValorLine,
    HeaderValorLine1,
    Line,
    Table,
    TableCell,
    TableCellExtended,
    TableContainer,
    TableHeader,
    TableHeaderCell,
  } from "./_cadastraravaliacaodesempenho";

const CadastrarAvaliacaoDesempenho = () => {
    return (
        <PageLayout>
            <Title
            titulo="Avaliação de Desempenho da Contratada"
            subtitulo="Checklist"
            />
            <Header>
                <HeaderLine>
                    <strong>CONTRATANTE:</strong>
                    OBRA EMPRESARIAL ABC
                </HeaderLine>
                <HeaderLine>
                    <strong>INÍCIO:</strong>
                    02/04/2019
                </HeaderLine>
                <HeaderLine>
                    <strong>PROJETO:</strong>
                    PROJETO CASAS E LARES
                </HeaderLine>
                <HeaderLine>
                    <strong>TÉRMINO:</strong>
                    12/08/2020
                </HeaderLine>
                <HeaderLine>
                    <strong>CONTRATADA:</strong>
                    EMPRESA FULANA
                </HeaderLine>
                <HeaderLine>
                    <strong>PRAZO EM DIAS:</strong>
                    300
                </HeaderLine>
            </Header>
            <Line />
            <ContainerValor>
            <HeaderValor>
                <HeaderValorLine>INDICADOR: Qualidade</HeaderValorLine>
                <HeaderValorLine>
                ITEM QUESITO: Conformidade na execução
                </HeaderValorLine>
                <HeaderValorLine1>
                <i>LEGENDA</i>
                <strong>1:</strong>
                SIM
                <strong>0:</strong>
                NÃO
                <strong>NA:</strong>
                NÃO APLICA
                </HeaderValorLine1>
            </HeaderValor>
            <TableContainer>
                <Table>
                <thead>
                    <tr>
                    <th />
                    <th />
                    <th />
                    <TableHeader>Peso Positivo</TableHeader>
                    <TableHeader>Peso Negativo</TableHeader>
                    </tr>
                    <tr>
                    <th />
                    <th />
                    <TableHeader>Avaliação</TableHeader>
                    <TableHeaderCell>3</TableHeaderCell>
                    <TableHeaderCell>2</TableHeaderCell>
                    <TableHeader>Peso</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <TableCell>1</TableCell>
                    <TableCellExtended>
                        Sem serviços recusados (retrabalhos) pela fiscalização
                        durante a execução
                    </TableCellExtended>
                    <TableCell>1</TableCell>
                    <TableCell>2</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>2</TableCell>
                    </tr>
                    <tr>
                    <TableCell>2</TableCell>
                    <TableCellExtended>
                        Atendimento às normas internas do cliente
                    </TableCellExtended>
                    <TableCell>NA</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>3</TableCell>
                    </tr>
                    <tr>
                    <TableCell>3</TableCell>
                    <TableCellExtended>
                        Sem serviços aceitos com restrições
                    </TableCellExtended>
                    <TableCell>0</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>1</TableCell>
                    </tr>
                    <tr>
                    <TableCell>4</TableCell>
                    <TableCellExtended>
                        Serviços executados com boa qualidade final (arremates,
                        acabamentos, apresentações etc.)
                    </TableCellExtended>
                    <TableCell>0</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>2</TableCell>
                    </tr>
                    <tr>
                    <TableCell>5</TableCell>
                    <TableCellExtended>
                        Controle de não conformidades com ações corretivas
                    </TableCellExtended>
                    <TableCell>1</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>1</TableCell>
                    </tr>
                    <tr>
                    <TableCell>6</TableCell>
                    <TableCellExtended>
                        Gerenciamento do estoque do materiais e insumos
                    </TableCellExtended>
                    <TableCell>NA</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>2</TableCell>
                    </tr>
                    <tr>
                    <TableCell>7</TableCell>
                    <TableCellExtended>
                        Sem serviços recusados (retrabalhos) pela fiscalização
                        durante a execução
                    </TableCellExtended>
                    <TableCell>10</TableCell>
                    <TableCell>3</TableCell>
                    <TableCell>2</TableCell>
                    <TableCell>10</TableCell>
                    </tr>
                </tbody>
                </Table>
            </TableContainer>
            </ContainerValor>
        </PageLayout>
    )
}
export default CadastrarAvaliacaoDesempenho;