import { useEffect, useState } from "react";
import { BsTrashFill } from "react-icons/bs";
import api from "../../services/api";
import { ModalSucesso } from "../../shared/ModalSucesso/ModalSucesso";
import PageLayout from "../../shared/PageLayout/PageLayout";
import Title from "../../shared/Title/Title";
import { messageError } from "../../utils/message";
import {
  Bar,
  ContainerInput,
  ContainerValor,
  InputDiv,
  InputText,
  LabelInput,
  SubmitArea,
  SubmitButton,
  Table,
  TableCell,
  TableContainer,
  TableHeader,
  TrashButton,
  TrashCell,
} from "./_cadastrarinsumos";

interface ISupplyCategory {
  id: string;
  name: string;
}

const CadastrarInsumos = () => {
  // usestate para controlar o modal
  const [incluido, setIncluido] = useState(false);
  const [excluido, setExcluido] = useState(false);
  const [name, setName] = useState("");

  const [supplyCategories, setSupplyCategories] = useState<ISupplyCategory[]>(
    []
  );

  const getData = async () => {
    try {
      const { data } = await api.get<ISupplyCategory[]>("/category");
      setSupplyCategories(data);
    } catch (err: any) {
      messageError(
        err?.response?.data?.message ||
          err?.message ||
          "Algo deu errado, tente novamente mais tarde!"
      );
      console.log("ERROR", err.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDeleteCategory = async (id: string) => {
    try {
      await api.delete(`/category/${id}`);

      setSupplyCategories((prevState) =>
        prevState.filter((category) => category.id !== id)
      );

      setExcluido(true);
    } catch (err: any) {
      messageError(
        err?.response?.data?.message ||
          err?.message ||
          "Algo deu errado, tente novamente mais tarde!"
      );
      console.log("ERROR", err.message);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await api.post<ISupplyCategory>("/category", {
        name,
      });
      setSupplyCategories([...supplyCategories, response.data]);
      setName("");
      setIncluido(true);
    } catch (err: any) {
      messageError(
        err?.response?.data?.message ||
          err?.message ||
          "Algo deu errado, tente novamente mais tarde!"
      );
      console.log("ERROR", err.message);
    }
  };

  return (
    <PageLayout>
      <Title
        titulo="Cadastrar categorias de insumo"
        subtitulo="Criar novos insumos"
      />
      <ContainerInput onSubmit={handleSubmit}>
        <InputDiv>
          <LabelInput>
            Categoria de Insumo:
            <InputText
              type="text"
              placeholder="Digite"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </LabelInput>
        </InputDiv>
        <SubmitArea>
          <SubmitButton type="submit">Nova categoria</SubmitButton>
        </SubmitArea>
      </ContainerInput>
      <Bar />
      <ContainerValor>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <TableHeader>Categoria</TableHeader>
                <th />
              </tr>
            </thead>
            <tbody>
              {supplyCategories.map((category) => (
                <tr key={category.id}>
                  <TableCell>{category.name}</TableCell>
                  <TrashCell>
                    <TrashButton
                      type="button"
                      onClick={() => handleDeleteCategory(category.id)}
                    >
                      <BsTrashFill />
                    </TrashButton>
                  </TrashCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </ContainerValor>
      <ModalSucesso
        content="Incluída com sucesso!"
        setState={setIncluido}
        state={incluido}
      />
      <ModalSucesso
        content="Excluído com sucesso!"
        setState={setExcluido}
        state={excluido}
      />
    </PageLayout>
  );
};
export default CadastrarInsumos;
