import { ReactNode } from "react";
import NavBar from "../NavBar/NavBar";
import UserHeader from "../UserHeader/UserHeader";
import { Container, Section } from "./_pagelayout";

interface Props {
    children: ReactNode;
}
const PageLayout = ({children}: Props) => {
    return(<Container>
        <NavBar />
        <Section>
            {children}
        </Section>
        <UserHeader />
    </Container>)
}
export default PageLayout;