import { toast } from 'react-toastify';

export const messageSuccess = (value: string) =>
  toast.success(value, {
    position: 'top-right',
    autoClose: 5000,
  });

export const messageError = (value: string) =>
  toast.error(value, {
    position: 'top-right',
    autoClose: 5000,
  });

export const messageInfo = (value: string) =>
  toast.info(value, {
    position: 'top-right',
    autoClose: 5000,
  });
