import styled from "styled-components";
import {
  InputTextGlobal,
  LabelTextGlobal,
  SelectGlobal,
  SubmitButtonGlobal,
  TitleValorGlobal,
} from "../../shared/PageStyles/_pagestyles";

export const ContainerInput = styled.form``;
export const LabelInput = styled(LabelTextGlobal)`
  grid-template-columns: 110px 200px;
  margin-top: 20px;
`;

export const InputText = styled(InputTextGlobal)``;

export const Select = styled(SelectGlobal)`
  color: #5e5e5e;
`;
export const SubArea = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-bottom: 10px;
`;
export const SubmitButton = styled(SubmitButtonGlobal)`
  width: 300px;
  font-size: 14px;
`;

export const ContainerValor = styled.div``;
export const TitleValor = styled(TitleValorGlobal)``;
export const TableContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 350px;
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e9ec;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #2f8055;
    border-radius: 10px;
  }
`;
export const Table = styled.table`
  border-spacing: 15px 5px;
  width: 100%;
  color: #5e5e5e;
`;
export const TableLine = styled.tr`
  height: 30px;
`;
export const TableCell1 = styled.td`
  background-color: #f6f6f6;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
`;
export const TableCell2 = styled.td`
  background-color: #f6f6f6;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
`;
export const TableCellButton = styled.td``;
export const TrashButton = styled.button`
  font-size: 25px;
  border: none;
  background-color: transparent;
  color: #5e5e5e;
  transition: all ease 0.2s;
  &:hover {
    color: black;
  }
`;
