import styled from "styled-components";

export const Titulo = styled.h1`
    color: #8F78B9;
    font-size: 22px;
    margin-top: 20px;
`;
export const Subtitulo = styled.h2`
    color: #777777;
    font-size: 14px;
    font-weight: 400;
`;