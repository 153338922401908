import {
  Container,
  EmailLine,
  InfoContainer,
  LogOutBtn,
  Photo,
  PhotoContainer,
  RoleLine,
  UserIconBtn,
  UserNameLine,
} from "./_userheader";
import { FaUserEdit } from "react-icons/fa";
import { HiOutlineLogout } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
const UserPhoto = require("../../assets/UserPhoto.png");
const UserHeader = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const handleLogout = () => {
    logout();
    navigate("/");
  };
  return (
    <Container>
      <PhotoContainer>
        <Photo
          src={user?.avatar || UserPhoto}
          onError={(e) => {
            e.currentTarget.src = UserPhoto;
          }}
        />
        <UserIconBtn onClick={() => navigate("/editarDadosAcesso")}>
          <FaUserEdit />
        </UserIconBtn>
      </PhotoContainer>
      <InfoContainer>
        <UserNameLine>{user.name}</UserNameLine>
        <RoleLine>Administrador</RoleLine>
        <EmailLine>{user.email}</EmailLine>
      </InfoContainer>
      <LogOutBtn type="button" onClick={handleLogout}>
        <HiOutlineLogout />
      </LogOutBtn>
    </Container>
  );
};

export default UserHeader;
