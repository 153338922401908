import styled from "styled-components";

export const Container = styled.div`
  width: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  margin-right: 20px;
`;
export const LogoArea = styled.div`
  width: 80%;
  margin-top: 20px;
`;
export const Logo = styled.img`
  width: 100%;
`;
interface OptionProps {
  active: boolean;
}
export const Option = styled.button<OptionProps>`
  display: grid;
  grid-template-columns: 22px 1fr;
  column-gap: 20px;
  align-items: center;
  width: 100%;
  padding-right: 10px;
  min-height: 40px;
  text-align: start;
  padding-left: 30px;
  background-color: transparent;
  border: none;
  color: #9e9e9e;
  margin-bottom: 5px;
  &:hover {
    color: #5c70a0;
  }
  svg {
    font-size: 22px;
    color: inherit;
  }
  ${(props) =>
    props.active &&
    `
        border-left: 5px solid #5C70A0;
        color: #5C70A0;
    `}
`;
export const OptionsContainer = styled.div`
  width: 100%;
  height: 420px;
  padding-top: 10px;
  overflow-y: auto;
  border: 1px solid #d8d8d8;
  border-radius: 0 10px 10px 0;
  box-shadow: 2px 2px 5px #d8d8d8f1;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e9ec;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #8f7cc4;
    border-radius: 10px;
  }
`;
