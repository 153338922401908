import React, { useEffect, useState } from "react";
import { BsTrashFill } from "react-icons/bs";
import api from "../../services/api";
import { ModalSucesso } from "../../shared/ModalSucesso/ModalSucesso";
import PageLayout from "../../shared/PageLayout/PageLayout";
import Title from "../../shared/Title/Title";
import { messageError } from "../../utils/message";
import {
  Bar,
  ContainerInput,
  ContainerValor,
  InputDiv,
  InputText,
  LabelInput,
  SubmitArea,
  SubmitButton,
  Table,
  TableCell,
  TableContainer,
  TableHeader,
  TrashButton,
  TrashCell,
} from "./_cadastrarunidades";

interface IUnit {
  id: string;
  name: string;
  initials: string;
  created_at: string;
  updated_at: string;
}

const CadastrarUnidades = () => {
  // usestate para controlar o modal
  const [incluido, setIncluido] = useState(false);
  const [excluido, setExcluido] = useState(false);
  const [name, setName] = useState("");
  const [initials, setInitials] = useState("");
  const [units, setUnits] = useState<IUnit[]>([]);

  const getData = async () => {
    try {
      const response = await api.get<IUnit[]>("/unit");
      setUnits(response.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await api.post<IUnit>("/unit", {
        name,
        initials,
      });

      setUnits([...units, response.data]);

      setIncluido(true);
    } catch (err: any) {
      messageError(
        err?.response?.data?.message ||
          err?.message ||
          "Algo deu errado, tente novamente mais tarde!"
      );
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await api.delete(`/unit/${id}`);

      setUnits(units.filter((unit) => unit.id !== id));

      setExcluido(true);
    } catch (err: any) {
      messageError(
        err?.response?.data?.message ||
          err?.message ||
          "Algo deu errado, tente novamente mais tarde!"
      );
      console.log(err);
    }
  };

  return (
    <PageLayout>
      <Title
        titulo="Cadastrar Unidade de Medida"
        subtitulo="Criar novas unidades de medida"
      />
      <ContainerInput onSubmit={handleSubmit}>
        <InputDiv>
          <LabelInput>
            Nome da unidade:
            <InputText
              type="text"
              placeholder="Digite"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </LabelInput>
          <LabelInput>
            Sigla:
            <InputText
              type="text"
              placeholder="Digite"
              required
              value={initials}
              onChange={(e) => setInitials(e.target.value)}
            />
          </LabelInput>
        </InputDiv>
        <SubmitArea>
          <SubmitButton type="submit">Adicionar Unidade de Medida</SubmitButton>
        </SubmitArea>
      </ContainerInput>
      <Bar />
      <ContainerValor>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <TableHeader>Nome da Unidade</TableHeader>
                <TableHeader>Sigla</TableHeader>
                <th />
              </tr>
            </thead>
            <tbody>
              {units.map((unit) => (
                <tr key={unit.id}>
                  <TableCell>{unit.name}</TableCell>
                  <TableCell>{unit.initials}</TableCell>
                  <TrashCell>
                    <TrashButton
                      type="button"
                      onClick={() => handleDelete(unit.id)}
                    >
                      <BsTrashFill />
                    </TrashButton>
                  </TrashCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </ContainerValor>

      <ModalSucesso
        content="Incluída com sucesso!"
        setState={setIncluido}
        state={incluido}
      />
      <ModalSucesso
        content="Excluído com sucesso!"
        setState={setExcluido}
        state={excluido}
      />
    </PageLayout>
  );
};
export default CadastrarUnidades;
