import { Container, Logo, LogoArea, Option, OptionsContainer } from "./_navbar";
import { BsCashCoin, BsPencilSquare } from "react-icons/bs";
import {
  FaBalanceScale,
  FaBoxes,
  FaClipboardCheck,
  FaListUl,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import NavList from "./NavList";
import { useEffect } from "react";
const logo = require("../../assets/logo_nobackground.png");
const NavBar = () => {
  const isActive = (way: string) => window.location.pathname === way;
  const navigate = useNavigate();
  let scrollRef: HTMLDivElement | null;
  useEffect(() => {
    const container = scrollRef;
    if (container) {
      NavList.map((item, index) => {
        if (index > 7) {
          if (window.location.pathname === item.href) {
            container.scrollTop = 52 * (index - 7);
          }
        }
        return null;
      });
    }
  }, []);
  return (
    <Container>
      <LogoArea>
        <Logo src={logo} />
      </LogoArea>
      <OptionsContainer
        ref={(ref) => {
          scrollRef = ref;
        }}
      >
        {NavList.map((item) => (
          <Option
            active={isActive(item.href)}
            onClick={() => {
              navigate(item.href);
            }}
          >
            <item.icon />
            {item.name}
          </Option>
        ))}
      </OptionsContainer>
      <div />
    </Container>
  );
};
export default NavBar;
