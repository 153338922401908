import styled from "styled-components";
import {
  InputTextGlobal,
  LabelTextGlobal,
  SubmitButtonGlobal,
} from "../../../shared/PageStyles/_pagestyles";

export const Container = styled.div`
  width: 700px;
  min-height: 400px;
  background-color: #fff;
`;
export const Header = styled.header`
  background-color: #5c70a0;
  color: #fff;
  height: 60px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;
export const CloseBtn = styled.button`
  color: #fff;
  background-color: transparent;
  border: none;
  font-size: 22px;
  height: 24px;
  width: 24px;
`;
export const LabelInput = styled(LabelTextGlobal)`
  grid-template-columns: 70px 1fr;
`;
export const LabelInput1 = styled(LabelTextGlobal)`
  grid-template-columns: 20px 1fr;
`;
export const LabelInput2 = styled(LabelTextGlobal)`
  grid-template-columns: 50px 1fr;
`;
export const LabelInput2Columns = styled(LabelTextGlobal)`
  grid-column: span 2;
  grid-template-columns: 70px 1fr;
`;
export const InputText = styled(InputTextGlobal)`
  border: 1px solid #cbcaca;
`;
export const Bar = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cbcaca;
  margin-bottom: 10px;
`;
export const SubmitButton = styled(SubmitButtonGlobal)`
  background-color: #5c70a0;
`;
export const ContainerInput = styled.form`
  margin: 20px 25px;
`;
export const LabelLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
`;
export const LabelLine1 = styled(LabelLine)`
  grid-template-columns: 1fr 1fr 120px;
`;
export const SubmitArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 30px;
`;
