import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import CadastrarAvaliacaoDesempenho from "../pages/CadastrarAvaliacaoDesempenho/CadastrarAvaliacaoDesempenho";
import CadastrarChecklists from "../pages/CadastrarChecklists/CadastrarChecklists";
import CadastrarChecklistsNovaSecao from "../pages/CadastrarChecklistsNovaSecao/CadastrarChecklistsNovaSecao";
import CadastrarChecklistsPerguntas from "../pages/CadastrarChecklistsPerguntas/CadastrarChecklistsPerguntas";
import CadastrarEventosSeguranca from "../pages/CadastrarEventosSeguranca/CadastrarEventosSeguranca";
import CadastrarInsumos from "../pages/CadastrarInsumos/CadastrarInsumos";
import CadastrarLicoesAprendidas from "../pages/CadastrarLicoesAprendidas/CadastrarLicoesAprendidas";
import CadastrarMaodeObra from "../pages/CadastrarMaoObra/CadastrarMaoObra";
import CadastrarMatrizRisco from "../pages/CadastrarMatrizRisco/CadastrarMatrizRisco";
import CadastrarMotivoMudanca from "../pages/CadastrarMotivoMudanca/CadastrarMotivoMudanca";
import CadastrarMudancasAlteracoes from "../pages/CadastrarMudancasAlteracoes/CadastrarMudancasAlteracoes";
import CadastrarQualidadeExecucao from "../pages/CadastrarQualidadeExecucao/CadastrarQualidadeExecucao";
import CadastrarRecursos from "../pages/CadastrarRecursos/CadastrarRecursos";
import CadastrarTipoParalisacoes from "../pages/CadastrarTipoParalisacoes/CadastrarTipoParalisacoes";
import CadastrarTiposCategoriasInsumos from "../pages/CadastrarTiposCategoriasInsumos/CadastrarTiposCategoriasInsumos";
import CadastrarUnidades from "../pages/CadastrarUnidades/CadastrarUnidades";
import CadastroEmpresas from "../pages/CadastroEmpresas/CadastroEmpresas";
import EditarDadosAcesso from "../pages/EditarDadosAcesso/EditarDadosAcesso";
import Login from "../pages/Login/Login";
import PlanosPagamentos from "../pages/PlanosPagamentos/PlanosPagamentos";
import RecuperarSenha from "../pages/RecuperarSenha/RecuperarSenha";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/recuperarSenha" element={<RecuperarSenha />} />
        <Route path="/cadastrarInsumos" element={<CadastrarInsumos />} />
        <Route path="/cadastrarMaodeObra" element={<CadastrarMaodeObra />} />
        <Route
          path="/cadastrarQualidadeExecucao"
          element={<CadastrarQualidadeExecucao />}
        />
        <Route
          path="/cadastrarParalisacoes"
          element={<CadastrarTipoParalisacoes />}
        />
        <Route
          path="/cadastrarEventosSeguranca"
          element={<CadastrarEventosSeguranca />}
        />
        <Route
          path="/cadastrarMudancasAlteracoes"
          element={<CadastrarMotivoMudanca />}
        />
        <Route
          path="/cadastrarLicoesAprendidas"
          element={<CadastrarLicoesAprendidas />}
        />
        <Route
          path="/cadastrarMatrizRisco"
          element={<CadastrarMatrizRisco />}
        />
        <Route
          path="/cadastrarAvaliacaoDesempenho"
          element={<CadastrarAvaliacaoDesempenho />}
        />
        <Route path="/cadastroEmpresas" element={<CadastroEmpresas />} />
        <Route path="/planosPagamentos" element={<PlanosPagamentos />} />
        <Route path="/cadastrarRecursos" element={<CadastrarRecursos />} />
        <Route
          path="/cadastrarChecklists/novaSecao/:checklistId"
          element={<CadastrarChecklistsNovaSecao />}
        />
        <Route
          path="/cadastrarChecklists/perguntas/:sectionId"
          element={<CadastrarChecklistsPerguntas />}
        />
        <Route path="/cadastrarChecklists" element={<CadastrarChecklists />} />
        <Route path="/cadastrarUnidades" element={<CadastrarUnidades />} />
        <Route path="/editarDadosAcesso" element={<EditarDadosAcesso />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};
export default AppRoutes;
