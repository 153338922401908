import styled from "styled-components";
import {
  InputTextGlobal,
  LabelTextGlobal,
  SelectGlobal,
  TitleValorGlobal,
} from "../../shared/PageStyles/_pagestyles";

export const ContainerInput = styled.form`
  label {
    font-weight: 700;
  }
  margin-top: 10px;
`;
export const LabelInput = styled(LabelTextGlobal)`
  width: fit-content;
  font-size: 16px;
`;
export const Select = styled(SelectGlobal)`
  width: 200px;
`;
export const InputText = styled(InputTextGlobal)``;
export const SubArea = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 40px;
`;
export const SubmitButton = styled.input`
  background-color: #5e90a2;
  border: none;
  color: white;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
`;

export const ContainerValor = styled.div``;
export const TitleValor = styled(TitleValorGlobal)`
  margin-bottom: 25px;
`;
export const TableContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 50vh;
  max-height: 350px;
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e9ec;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #2f8055;
    border-radius: 10px;
  }
`;
export const Table = styled.table`
  border-spacing: 15px 5px;
  width: 100%;
  color: #5e5e5e;
`;
export const TableLine = styled.tr`
  height: 30px;
`;
export const TableCell1 = styled.td`
  background-color: #f6f6f6;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
`;
export const TableCell2 = styled.td`
  background-color: #f6f6f6;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
`;
export const TableCellButton = styled.td``;
export const TrashButton = styled.button`
  font-size: 22px;
  border: none;
  background-color: transparent;
  color: #5e5e5e;
  transition: all ease 0.2s;
  &:hover {
    color: black;
  }
`;
