import styled from "styled-components";

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  color: #5E5E5E;
`;
export const HeaderLine = styled.div`
  font-size: 13px;
  strong {
    font-size: 14px;
    margin-right: 5px;
  }
`;
export const Line = styled.div`
  height: 1px;
  background-color: #0003;
  width: 95%;
  margin: 10px 0;
`;
export const ContainerValor = styled.div``;
export const HeaderValor = styled.div`
  color: #5E5E5E;
`;
export const HeaderValorLine = styled.div`
  font-size: 14px;
`;
export const HeaderValorLine1 = styled(HeaderValorLine)`
  i {
    font-style: normal;
    font-weight: 700;
  }
  strong {
    margin-right: 2px;
    margin-left: 10px;
  }
`;
export const TableContainer = styled.div`
  width: 90%;
`;
export const Table = styled.table`
  border-spacing: 3px;
`;
export const TableHeader = styled.th`
  color: #7f7f7f;
  font-size: 13px;
  padding: 0 10px;
`;
export const TableHeaderCell = styled.th`
  background-color: #eee8fb;
  color: #868688;
  font-size: 12px;
  height: 30px;
`;
export const TableCell = styled.td`
  text-align: center;
  background-color: #f6f6f6;
  color: #878787;
  font-size: 12px;
  min-width: 40px;
  padding: 0 5px;
  height: 30px;
`;
export const TableCellExtended = styled(TableCell)`
  text-align: start;
  width: 100%;
`;
