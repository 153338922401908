import styled from "styled-components";
import { ContainerValorGlobal, InputTextGlobal, LabelTextGlobal, SubmitAreaGlobal, SubmitButtonGlobal, ValorTitleGlobal } from "../../shared/PageStyles/_pagestyles";

export const ContainerInput = styled.form`
    margin-top: 20px;
`;
export const InputDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
`;
export const InputText = styled(InputTextGlobal)``;


export const LabelInput = styled(LabelTextGlobal)`
    grid-template-columns: 100px 1fr;
`;

export const SubmitArea = styled(SubmitAreaGlobal)`
    
`;
export const SubmitButton = styled(SubmitButtonGlobal)`
    font-size: 12px;
`;
export const ContainerValor = styled(ContainerValorGlobal)``;
export const TableContainer = styled.div`
    padding-right: 1%;
    overflow-y: scroll;
    height: 350px;
    width: 98%;
    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background: #e6e9ec;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #2f8055;
        border-radius: 10px;
    }
`;
export const Table = styled.table`
    width: 100%;
    border-spacing: 15px 5px;
`;
export const TableHeader = styled.th`
    color: #7A7A7A;
`;
export const TableCell = styled.td`
    text-align: center;
    background-color: #F6F6F6;
    color: #7A7A7A;
    font-size: 15px;
`;
export const TrashCell = styled.td`
    height: 35px;
    border-radius: 5px;
    width: 40px;
`;
export const TrashButton = styled.button`
    border: none;
    background-color: transparent;
    font-size: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    color: #5E5E5E;
`;

export const Bar = styled.div`
    width: 100%;
    height: 1px;
    background-color: #CBCACA;
    margin: 30px 0;
`;