import styled from "styled-components";

export const BackgroundTop = styled.div`
  img {
    height: 90vh;
    position: absolute;
    top: -330px;
    left: -150px;
    z-index: -1;
  }

  @media (max-width: 600px) {
    img {
      display: none;
    }
  }
`;
export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  transition: all ease 0.3s;
  height: 20vh;
`;
export const Logo = styled.img`
    height: 100%;
`;

export const Container = styled.section`
  font-family: var(--fontRoboto);
  width: 350px;
  margin: 0 auto;
  padding: 20px;
  background: #fbfbfb;
  box-shadow: 0px 5px 6px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;

export const Form = styled.form`
  width: 90%;
  
`;
export const InputText = styled.input`
    width: 100%;
    padding: 10px;
    margin: 15px 0;
    display: block;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--purple);
    box-sizing: border-box;
    text-align: center;

    ::placeholder {
    text-align: center;
    font-size: 1rem;
    }

    :focus {
    outline: none;
    }
`;
export const ButtonLogin = styled.button`
  display: block;
  margin: 35px auto 20px auto;
  border: none;
  font-family: var(--fontRoboto);
  font-size: 1rem;
  border-radius: 5px;
  font-weight: bolder;
  color: var(--white);
  background: #8f78b9;
  text-decoration: none;
  width: 100%;
  height: 40px;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ButtonCadastro = styled.button`
  border: none;
  color: #32948c;
  font-weight: bold;
  background-color: #fbfbfb;
  margin-left: 10px;
  font-size: inherit;
`;

export const ChangePassword = styled.div`
  text-align: center;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--blue);
  color: var(--blue);
  font-size: 0.9rem;
  cursor: pointer;
`;

export const CheckBox = styled.div`
  text-align: center;
  margin-bottom: 15px;
  color: #868e96;

  input {
    margin: 0 5px;
  }
`;

export const LinkCadastro = styled.div`
  text-align: center;

  p {
    color: var(--gray);
  }

  span {
    text-decoration: none;
    color: var(--green);
    cursor: pointer;
  }
`;

export const BackgroundBottom = styled.div`
  img {
    position: fixed;
    height: 130vh;
    top: 80px;
    left: 800px;
    z-index: -1;
  }

  @media (max-width: 800px) {
    img {
      display: none;
    }
  }
`;
interface ControllerProps {
  timer: boolean;
}
export const ControllerStyle = styled.div<ControllerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  ${BackgroundTop}, 
  ${BackgroundBottom}, 
  ${Container} {
    transition: visibility 0s, opacity 0.6s linear;
  }
  ${props => !props.timer ? `
    ${BackgroundTop}, 
    ${BackgroundBottom}, 
    ${Container} {
      opacity: 0;
    }
    ${LogoContainer} {
      top: 50%;
      transform: translate(-50%, -50%);
      height: 25vh;
    }
  `:`
  
  
  `}
`;