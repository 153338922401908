import styled from "styled-components";
import {
  ContainerValorGlobal,
  InputTextGlobal,
  LabelTextGlobal,
  SubmitAreaGlobal,
  SubmitButtonGlobal,
  TitleValorGlobal,
  ValorTitleGlobal,
} from "../../shared/PageStyles/_pagestyles";

export const ContainerInput = styled.form`
  margin-top: 20px;
`;
export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`;
export const InputText = styled(InputTextGlobal)``;

export const TitleValor = styled(TitleValorGlobal)`
  margin-bottom: 25px;
`;

export const LabelInput = styled(LabelTextGlobal)`
  grid-template-columns: 70px 1fr;
`;

export const SubmitArea = styled(SubmitAreaGlobal)``;
export const SubmitButton = styled(SubmitButtonGlobal)`
  font-size: 0.875rem;
  background-color: #5e90a2;
  border: none;
  color: white;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
`;
export const ContainerValor = styled(ContainerValorGlobal)``;
export const TableContainer = styled.div`
  padding-right: 1%;
  overflow-y: scroll;
  height: 350px;
  width: 98%;
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e9ec;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #2f8055;
    border-radius: 10px;
  }
`;
export const Table = styled.table`
  width: 100%;
  border-spacing: 10px 5px;
`;
export const TableHeader = styled.th`
  color: #7a7a7a;
  text-align: left;

  padding: 0.75rem;
`;
export const TableCell = styled.td`
  text-align: center;
  background-color: #f6f6f6;
  color: #7a7a7a;
  font-size: 15px;
  text-align: left;
  padding: 0.75rem;
`;
export const TrashCell = styled.td`
  height: 35px;
  border-radius: 5px;
  width: 40px;
`;
export const TrashButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #5e5e5e;
`;

export const Bar = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cbcaca;
  margin: 30px 0;
`;
