import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
`;
export const Section = styled.section`
    width: calc(100vw - 600px);
    box-sizing: border-box;
    padding: 10px 5px;
`;