import PageLayout from "../../shared/PageLayout/PageLayout"
import Title from "../../shared/Title/Title";
import {
    ContainerValor,
    Header,
    HeaderLine,
    HeaderValor,
    HeaderValorLine,
    HeaderValorLine1,
    Line,
    Table,
    TableCell,
    TableCellExtended,
    TableContainer,
    TableHeader,
    TableHeaderCell,
  } from "./_cadastrarmatrizrisco";

const CadastrarMatrizRisco = () => {
    return(
    <PageLayout>
        <Title titulo="Matriz de Riscos e Problemas" subtitulo="Checklist" />
        <Header>
          <HeaderLine>
            <strong>CONTRATANTE:</strong>
            OBRA EMPRESARIAL ABC
          </HeaderLine>
          <HeaderLine>
            <strong>INÍCIO:</strong>
            02/04/2019
          </HeaderLine>
          <HeaderLine>
            <strong>PROJETO:</strong>
            PROJETO CASAS E LARES
          </HeaderLine>
          <HeaderLine>
            <strong>TÉRMINO:</strong>
            12/08/2020
          </HeaderLine>
          <HeaderLine>
            <strong>CONTRATADA:</strong>
            EMPRESA FULANA
          </HeaderLine>
          <HeaderLine>
            <strong>PRAZO EM DIAS:</strong>
            300
          </HeaderLine>
        </Header>
        <Line />
        <ContainerValor>
          <HeaderValor>
            <HeaderValorLine1>
              <i>ESTRATÉGIAS: </i>
              <strong>Mi:</strong>
              Mitigar
              <strong>Me:</strong>
              Melhorar
              <strong>Tr:</strong>
              Transferir
              <strong>Ac:</strong>
              Aceitar
              <strong>Ev:</strong>
              Evitar
            </HeaderValorLine1>
            <HeaderValorLine1>
              <i>CLASSIFICAÇÃO:</i>
              <strong>Ri:</strong>
              Risco
              <strong>Pr:</strong>
              Problema
              <strong>Op:</strong>
              Oportunidade
            </HeaderValorLine1>
            <HeaderValorLine1>
              <i>PRIORIDADE:</i>
              <p>em relação ao impacto da ocorrência para o empreendimento |</p>
              <strong>Ba:</strong>
              Baixa
              <strong>Md:</strong>
              Média
              <strong>Al:</strong>
              Alta
            </HeaderValorLine1>
          </HeaderValor>
          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <TableHeader>Item</TableHeader>
                  <TableHeader>Mão de Obra Própria</TableHeader>
                  <TableHeader>Sim</TableHeader>
                  <TableHeader>Pontos</TableHeader>
                  <TableHeader>Classificação</TableHeader>
                  <TableHeader>Estratégia</TableHeader>
                  <TableHeader>Prioridade</TableHeader>
                  <TableHeader>Observações</TableHeader>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TableCell>1</TableCell>
                  <TableCellExtended>
                    Muitas correções de erros in loco ou irregularidades de
                    caráter técnico identificados pela fiscalização durante a
                    obra
                  </TableCellExtended>
                  <TableCell>X</TableCell>
                  <TableCell>1</TableCell>
                  <TableCell>Pr</TableCell>
                  <TableCell>Mi</TableCell>
                  <TableCell>-</TableCell>
                  <TableCellExtended>-</TableCellExtended>
                </tr>
                <tr>
                  <TableCell>2</TableCell>
                  <TableCellExtended>
                    Má qualidade de mão de obra (problemas com mão-de-obra que
                    comprometeram tecnicamente e/ou funcinalmente)
                  </TableCellExtended>
                  <TableCell>X</TableCell>
                  <TableCell>2</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCellExtended>-</TableCellExtended>
                </tr>
                <tr>
                  <TableCell>5</TableCell>
                  <TableCellExtended>
                    A Contratada não toma as providÊncias necessárias e
                    suficientes com relação a possíveis impedimentos que possam
                    comprometer o andamento normal dos serviços
                  </TableCellExtended>
                  <TableCell>X</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCellExtended>-</TableCellExtended>
                </tr>
                <tr>
                  <TableCell>10</TableCell>
                  <TableCellExtended>
                    A Contratada não toma as providÊncias necessárias e
                    suficientes com relação a possíveis impedimentos que possam
                    comprometer o andamento normal dos serviços
                  </TableCellExtended>
                  <TableCell>X</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCellExtended>-</TableCellExtended>
                </tr>
              </tbody>
            </Table>
          </TableContainer>
        </ContainerValor>
    </PageLayout>)
}
export default CadastrarMatrizRisco;