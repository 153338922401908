import styled from "styled-components";
import {
  ContainerValorGlobal,
  InputTextGlobal,
  LabelTextGlobal,
  SubmitAreaGlobal,
  SubmitButtonGlobal,
} from "../../shared/PageStyles/_pagestyles";

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const BackButton = styled(SubmitButtonGlobal)`
  min-width: 100px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 14px;
  svg {
    font-size: 20px;
    margin-right: 10px;
  }
`;

export const CreateButton = styled(SubmitButtonGlobal)`
  font-size: 14px;
`;

export const ContainerInput = styled.form`
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
`;

export const InputText = styled(InputTextGlobal)``;

export const LabelInput = styled(LabelTextGlobal)`
  grid-template-columns: 100px 1fr;
`;

export const LabelText = styled(LabelTextGlobal)`
  height: 80px;
  align-items: flex-start;
  display: grid;
  grid-template-columns: 100px 1fr;
  width: 400px;
  margin-bottom: 0;
`;

export const TextArea = styled.textarea`
  border: 1px solid #9870d5;
  resize: none;
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  border-radius: 5px;
  font-family: "roboto";
`;

export const SubmitArea = styled(SubmitAreaGlobal)`
  margin-top: 50px;
`;

export const SubmitButton = styled(SubmitButtonGlobal)`
  font-size: 15px;
`;

export const Form = styled.form``;

export const ContainerValor = styled(ContainerValorGlobal)``;

export const TableContainer = styled.div`
  padding-right: 1%;
  overflow-y: scroll;
  height: 350px;
  width: 98%;
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e9ec;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #2f8055;
    border-radius: 10px;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 15px 5px;
`;

export const TableHeader = styled.th`
  color: #7a7a7a;
  font-size: 14px;
`;

export const TableCell = styled.td`
  text-align: center;
  background-color: #f6f6f6;
  color: #7a7a7a;
  font-size: 15px;
  text-align: start;
  padding: 0 10px;
`;

export const TableCellInput = styled.input`
  width: 100%;

  background: transparent;
  border: none;

  font-size: inherit;
  color: inherit;
  text-align: start;

  resize: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #7a7a7a;
  }
`;

export const TableCellCheckbox = styled(TableCell)`
  text-align: center;
`;

export const IconsCell = styled.td`
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
`;

export const IconButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 22px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #5e5e5e;
  margin: 0 2px;
`;

export const Bar = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cbcaca;
  margin: 30px 0;
`;
