import { BsCashCoin, BsFillPersonFill, BsPencilSquare } from "react-icons/bs";
import {
  FaBalanceScale,
  FaBoxes,
  FaClipboardCheck,
  FaListUl,
} from "react-icons/fa";

const NavList = [
  {
    name: "Cadastrar Empresas",
    href: "/cadastroEmpresas",
    icon: BsPencilSquare,
  },
  {
    name: "Cadastrar Planos e Pagamentos",
    href: "/planosPagamentos",
    icon: BsCashCoin,
  },
  /* {
    name: "Cadastrar Recursos",
    href: "/cadastrarRecursos",
    icon: FaListUl,
  }, */
  {
    name: "Cadastrar Checklist",
    href: "/cadastrarChecklists",
    icon: FaClipboardCheck,
  },
  {
    name: "Cadastrar Unidades",
    href: "/cadastrarUnidades",
    icon: FaBalanceScale,
  },
  {
    name: "Cadastrar Tipos ou Categorias de insumos",
    href: "/cadastrarInsumos",
    icon: FaBoxes,
  },
  /* {
    name: "Cadastrar Tipos de Mão de Obra",
    href: "/cadastrarMaodeObra",
    icon: BsFillPersonFill,
  }, */
  {
    name: "Cadastrar Qualidade - execução",
    href: "/cadastrarQualidadeExecucao",
    icon: BsCashCoin,
  },
  {
    name: "Cadastrar Tipos de Paralisações",
    href: "/cadastrarParalisacoes",
    icon: BsCashCoin,
  },
  {
    name: "Cadastrar Segurança",
    href: "/cadastrarEventosSeguranca",
    icon: BsCashCoin,
  },

  {
    name: "Cadastrar Motivos de Mudanças e Alterações",
    href: "/cadastrarMudancasAlteracoes",
    icon: BsCashCoin,
  },
  /*{
    name: "Cadastrar Lições Aprendidas",
    href: "/cadastrarLicoesAprendidas",
    icon: BsCashCoin,
  },
   {
    name: "Cadastrar Matriz de Riscos e Problemas",
    href: "/cadastrarMatrizRisco",
    icon: BsCashCoin,
  },
  {
    name: "Cadastrar Avaliação de Desempenho",
    href: "/cadastrarAvaliacaoDesempenho",
    icon: BsCashCoin,
  }, */
];

export default NavList;
