import styled from "styled-components";

export const ErrorMessage = styled.div`
    color: #f00;
`;

export const LabelTextGlobal = styled.label`
    display: grid;
    grid-template-columns: 120px 1fr;
    align-items: center;
    font-size: 14px;
    column-gap: 10px;
    height: 35px;
    margin-bottom: 15px;
    color: #5E5E5E;
    font-weight: 700;
`;
export const InputTextGlobal = styled.input`
    border: 1px solid #9870D5;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-family: Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16px;
`;
export const TextAreaGlobal = styled.textarea`
    border: 1px solid #b293df;
    border-radius: 5px;
    padding: 5px 10px;
    resize: none;
    box-sizing: border-box;
    font-family: "calibri";
    font-size: 16px;
`;
export const TitleValorGlobal = styled.h2`
    font-size: 18px;
    font-weight: 700;
    color: #5E5E5E;
`;
export const SelectGlobal = styled.select`
    border: 1px solid #9870D5;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-family: Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16px;
    color: #5E5E5E;
`;
export const SubmitButtonGlobal = styled.button`
    background-color: #5E90A2;
    color: #fff;
    min-width: 180px;
    width: fit-content;
    padding: 0 20px;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    height: 35px;
    box-sizing: border-box;
    box-shadow: 1px 1px 5px #0008;
    font-family: "poppins";
`;
export const SubmitAreaGlobal = styled.div`
    display: flex;
    justify-content: end;
`;
export const ContainerValorGlobal = styled.div`
    margin-top: 20px;
`;
export const ValorTitleGlobal = styled.h2`
    color: #5e5e5e;
    font-size: 15px;
    margin-bottom: 10px;
`;
