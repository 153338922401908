import PageLayout from "../../shared/PageLayout/PageLayout";
import Title from "../../shared/Title/Title";
import { AiFillDelete } from "react-icons/ai";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ModalSucesso } from "../../shared/ModalSucesso/ModalSucesso";
import {
  ContainerInput,
  ContainerValor,
  LabelInput,
  Select,
  SubArea,
  SubmitButton,
  TitleValor,
  TableContainer,
  Table,
  TableLine,
  TableCell1,
  TableCell2,
  TableCellButton,
  TrashButton,
  InputText,
} from "./_cadastrarqualidadeexecucao";
import api from "../../services/api";

interface SubmitProps {
  name: string;
  type: string;
}

interface GetDataProps {
  id: string;
  name: string;
  type: string;
  company_id: string;
  created_at: string;
  updated_at: string;
}

const CadastrarQualidadeExecucao = () => {
  const [incluido, setIncluido] = useState(false);
  const [excluido, setExcluido] = useState(false);
  const [qualidades, setQualidades] = useState<GetDataProps[]>([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    try {
      await api.get("/quality?type=EXECUTION").then((res) => {
        setQualidades(res.data);
      });
    } catch (err: any) {
      console.log("ERROR", err.message);
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SubmitProps>({});
  const handleIncluir: SubmitHandler<SubmitProps> = async (value) => {
    try {
      const response = await api.post<GetDataProps>("/quality", {
        name: value.name,
        type: "EXECUTION",
      });

      setQualidades((prevState) => [...prevState, response.data]);

      reset();
      /* await api.post("/quality", types).then((res) => {
        setIncluido(true);
        reset({ ...value });
        getData();
      }); */
    } catch (err: any) {
      console.log("ERROR", err);
    }
  };
  const handleDelete = async (id: string) => {
    try {
      await api.delete(`/quality/${id}`).then((res) => {
        setExcluido(true);
        getData();
      });
    } catch (err: any) {
      console.log("ERROR", err.message);
    }
  };
  return (
    <PageLayout>
      <Title
        titulo="Cadastrar Eventos de Qualidade de Execução"
        subtitulo="Cadastrar tipo de eventos"
      />
      <ContainerInput onSubmit={handleSubmit(handleIncluir)}>
        <LabelInput>
          Tipo de Evento:
          {/* <Select defaultValue="">
              <option value="" hidden disabled>
                Selecione
              </option>
              <option disabled>
                Tipos de eventos de qualidade na execução
              </option>
              <option value="Material não aceito">Material não aceito</option>
              <option value="Material aceito com restrição">
                Material aceito com restrição
              </option>
              <option value="Má qualidade final do serviço">
                Má qualidade final do serviço
              </option>
              <option value="Correção feita in loco">
                Correção feita in loco
              </option>
              <option value="Seviço aceito com restrição">
                Seviço aceito com restrição
              </option>
              <option value="serviço não aceito - retrabalho">
                serviço não aceito - retrabalho
              </option>
              <option value="Retrabalho não aceito">
                Retrabalho não aceito
              </option>
              <option value="Retrabalho aceito com restrição">
                Retrabalho aceito com restrição
              </option>
            </Select> */}
          <InputText
            type="text"
            placeholder="Digite"
            {...register("name", {
              required: true,
            })}
            required
          />
        </LabelInput>
        <SubArea>
          <SubmitButton type="submit">
            Cadastrar Qualidade Execução
          </SubmitButton>
        </SubArea>
      </ContainerInput>
      <ContainerValor>
        <TitleValor>Eventos Cadastrados</TitleValor>
        <TableContainer>
          <Table>
            <tbody>
              {!!qualidades &&
                qualidades.map((item) => (
                  <TableLine>
                    <TableCell1>Evento</TableCell1>
                    <TableCell2>{item.name}</TableCell2>
                    <TableCellButton>
                      <TrashButton
                        type="button"
                        onClick={() => handleDelete(item.id)}
                      >
                        <AiFillDelete />
                      </TrashButton>
                    </TableCellButton>
                  </TableLine>
                ))}
            </tbody>
          </Table>
        </TableContainer>
      </ContainerValor>
      <ModalSucesso
        content="Incluido com sucesso!"
        state={incluido}
        setState={setIncluido}
      />
      <ModalSucesso
        content="Excluido com sucesso!"
        state={excluido}
        setState={setExcluido}
      />
    </PageLayout>
  );
};
export default CadastrarQualidadeExecucao;
