import PageLayout from "../../shared/PageLayout/PageLayout"
import Title from "../../shared/Title/Title";
import {
    ContainerValor,
    Header,
    HeaderLine,
    HeaderValor,
    HeaderValorLine,
    HeaderValorLine1,
    Line,
    Table,
    TableCell,
    TableCellExtended,
    TableContainer,
    TableHeader,
    TableHeaderCell,
  } from "./_cadastrarlicoesaprendidas";

const CadastrarLicoesAprendidas = () => {
    return (
        <PageLayout>
            <Title titulo="Lições Aprendidas" subtitulo="Checklist" />
            <Header>
                <HeaderLine>
                    <strong>CONTRATANTE:</strong>
                    OBRA EMPRESARIAL ABC
                </HeaderLine>
                <HeaderLine>
                    <strong>INÍCIO:</strong>
                    02/04/2019
                </HeaderLine>
                <HeaderLine>
                    <strong>PROJETO:</strong>
                    PROJETO CASAS E LARES
                </HeaderLine>
                <HeaderLine>
                    <strong>TÉRMINO:</strong>
                    12/08/2020
                </HeaderLine>
                <HeaderLine>
                    <strong>CONTRATADA:</strong>
                    EMPRESA FULANA
                </HeaderLine>
                <HeaderLine>
                    <strong>PRAZO EM DIAS:</strong>
                    300
                </HeaderLine>
                </Header>
                <Line />
                <ContainerValor>
                <TableContainer>
                    <Table>
                    <thead>
                        <tr>
                        <th />
                        <th />
                        <TableHeader>Sim</TableHeader>
                        <TableHeader>Pontos</TableHeader>
                        </tr>
                        <tr>
                        <TableHeader>Item</TableHeader>
                        <TableHeader>Mão de Obra Própria</TableHeader>
                        <TableHeaderCell>6</TableHeaderCell>
                        <TableHeaderCell>6</TableHeaderCell>
                        <TableHeader>Ação Executada</TableHeader>
                        <TableHeader>Lições Aprenda</TableHeader>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <TableCell>1</TableCell>
                        <TableCellExtended>
                            Muitas correções de erros in loco ou irregularidades de
                            caráter técnico identificados pela fiscalização durante a
                            obra
                        </TableCellExtended>
                        <TableCell>X</TableCell>
                        <TableCell>1</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        </tr>
                        <tr>
                        <TableCell>2</TableCell>
                        <TableCellExtended>
                            Má qualidade de mão de obra (problemas com mão-de-obra que
                            comprometeram tecnicamente e/ou funcinalmente)
                        </TableCellExtended>
                        <TableCell>X</TableCell>
                        <TableCell>2</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        </tr>
                        <tr>
                        <TableCell>5</TableCell>
                        <TableCellExtended>
                            A Contratada não toma as providÊncias necessárias e
                            suficientes com relação a possíveis impedimentos que possam
                            comprometer o andamento normal dos serviços
                        </TableCellExtended>
                        <TableCell>X</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        </tr>
                    </tbody>
                    </Table>
                </TableContainer>
            </ContainerValor>
        </PageLayout>
    )
}
export default CadastrarLicoesAprendidas;