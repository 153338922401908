import PageLayout from "../../shared/PageLayout/PageLayout";
import Title from "../../shared/Title/Title";
import { AiFillDelete } from "react-icons/ai";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import {
  ContainerInput,
  ContainerValor,
  InputsDiv,
  InputsDiv1,
  InputText,
  LabelInput,
  LabelText,
  TextArea,
  SubmitButton,
  ValorTitle,
  ListArea,
  Card,
  CardItem,
  CardItem1,
  TrashButton,
  Select,
} from "./_cadastrartipoparalisacoes";
import api from "../../services/api";
import { ModalSucesso } from "../../shared/ModalSucesso/ModalSucesso";
import { ParalisacoesScheme } from "../../validations/CadastroScheme";

interface GetDataProps {
  id: string;
  name: string;
  description: string;
  type: string;
  company_id: string;
  created_at: string;
  updated_at: string;
}

interface SubmitProps {
  name: string;
  description: string;
  type: string;
}

const CadastrarTipoParalisacoes = () => {
  const [incluido, setIncluido] = useState(false);
  const [excluido, setExcluido] = useState(false);
  const [paralisacoes, setParalisacoes] = useState<GetDataProps[]>([]);
  const [error, setError] = useState(false);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    try {
      await api.get("/stoppage").then((res) => {
        setParalisacoes(res.data);
      });
    } catch (err: any) {
      console.log("ERROR", err);
      setError(true);
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SubmitProps>({
    resolver: yupResolver(ParalisacoesScheme),
  });

  const handleIncluir: SubmitHandler<SubmitProps> = async (value) => {
    console.log(value);
    try {
      await api.post("/stoppage", value).then(() => {
        setIncluido(true);
        getData();
      });
    } catch (err: any) {
      console.log("ERROR", err);
    }
  };
  const handleDelete = async (id: string) => {
    try {
      await api.delete(`/stoppage/${id}`).then(() => {
        setExcluido(true);
        getData();
      });
    } catch (err: any) {
      console.log("ERROR", err.message);
    }
  };
  return (
    <PageLayout>
      <Title
        titulo="Cadastrar Tipos de paralisações"
        subtitulo="Criar novos tipos de paralisações"
      />
      <ContainerInput onSubmit={handleSubmit(handleIncluir)}>
        <InputsDiv>
          <LabelInput>
            Nome:
            {/* <Select defaultValue="" {...register("name")}>
                <option value="" hidden disabled>
                    Selecione
                </option>
                <option value="Acidente">Acidente</option>
                <option value="Falta de energia">Falta de energia</option>
                <option value="Liberação de área">Liberação de área</option>
                <option value="Chuva">Chuva</option>
                <option value="Raios e ventos">Raios e ventos</option>
                <option value="Mão de obra">Mão de obra</option>
                <option value="Materiais">Materiais</option>
                <option value="Equipamentos">Equipamentos</option>
                <option value="Planejamento">Planejamento</option>
                <option value="Projeto">Projeto</option>
                <option value="Contrato">Contrato</option>
                <option value="Graves">Graves</option>
                </Select> */}
            <InputText
              type="text"
              placeholder="Digite"
              {...register("name")}
              required
            />
          </LabelInput>
        </InputsDiv>
        <InputsDiv1>
          <LabelText>
            Descrição:
            <TextArea
              cols={50}
              rows={10}
              placeholder="Digite"
              {...register("description")}
              required
            />
          </LabelText>

          <SubmitButton type="submit">Cadastrar Paralisações</SubmitButton>
        </InputsDiv1>
      </ContainerInput>
      <ContainerValor>
        <ValorTitle>Paralisações cadastradas</ValorTitle>
        <ListArea>
          {!!paralisacoes &&
            paralisacoes.map((item) => (
              <Card key={item.id}>
                <CardItem>
                  <strong>Nome:</strong>
                  {item.name}
                </CardItem>
                <CardItem1>
                  <strong>Descrição:</strong>
                  {item.description}
                </CardItem1>
                <TrashButton
                  type="button"
                  onClick={() => handleDelete(item.id)}
                >
                  <AiFillDelete />
                </TrashButton>
              </Card>
            ))}
        </ListArea>
      </ContainerValor>
      <ModalSucesso
        content="Incluido com sucesso!"
        state={incluido}
        setState={setIncluido}
      />
      <ModalSucesso
        content="Excluido com sucesso!"
        state={excluido}
        setState={setExcluido}
      />
    </PageLayout>
  );
};
export default CadastrarTipoParalisacoes;
