import * as yup from "yup";

export const DisciplinaScheme = yup.object().shape({
  name: yup.string().required("Preencha o nome"),
});
export const SupplyScheme = yup.object().shape({
  name: yup.string().required("Preencha o nome do insumo"),
  unit_id: yup.string().required("Preencha o nome do insumo"),
  quantity: yup.string().required("Preencha o nome do insumo"),
  unit_price: yup.string().required("Preencha o nome do insumo"),
  total: yup.string().required("Preencha o nome do insumo"),
  category: yup.string().required("Preencha o nome do insumo"),
});
export const RecursosScheme = yup.object().shape({
  discipline_id: yup.string().required("Preencha o campo de disciplina"),
  hour_price: yup.number().required("Preencha o preço por hora"),
  occupation: yup.string().required("Preencha a função"),
});
export const LaborScheme = yup.object().shape({
  name: yup.string().required("Preencha o campo de disciplina"),
  description: yup.string().optional().typeError(""),
  type: yup.string().required("Preencha a função"),
});
export const ParalisacoesScheme = yup.object().shape({
  name: yup.string().required("Preencha o campo de disciplina"),
  description: yup.string().required("Preencha o preço por hora"),
});
export const PolicyScheme = yup.object().shape({
  name: yup.string().required("Preencha o campo de disciplina"),
  description: yup.string().required("Preencha a função"),
});
export const ChangesScheme = yup.object().shape({
  name: yup.string().required("Preencha o campo de nome"),
});
export const UsersScheme = yup.object().shape({
  name: yup.string().required("Preencha o campo de disciplina"),
  email: yup.string().required("Preencha o campo de disciplina"),
  password: yup.string().required("Preencha o campo de disciplina"),
  sector: yup.string().required("Preencha o campo de disciplina"),
  role: yup.string().required("Preencha o campo de disciplina"),
});
export const CreatePasswordScheme = yup.object().shape({
  senha1: yup
    .string()
    .required("Preencha o campo de senha")
    .min(8, "A senha deve ter mais que 8 caractéres"),
  senha2: yup
    .string()
    .oneOf([yup.ref("senha1"), null], "As senhas devem ser iguais"),
});
export const CheckListScheme = yup.object().shape({
  name: yup.string().required("Preencha o campo de nome"),
  description: yup.string().required("Preencha o campo de descrição"),
});
export const CheckListNewSectionScheme = yup.object().shape({
  name: yup.string().required("Preencha o campo de disciplina"),
});
export const ProjectScheme = yup.object().shape({
  name: yup.string().required("Preencha o campo de disciplina"),
});
export const CriticAnalysisScheme = yup.object().shape({
  name: yup.string().required("Preencha o campo de nome"),
  description: yup.string().required("Preencha o campo de descrição"),
});
