import styled from "styled-components";
import {
  LabelTextGlobal,
  SelectGlobal,
  TextAreaGlobal,
  TitleValorGlobal,
} from "../../shared/PageStyles/_pagestyles";

export const ContainerInput = styled.form`
  margin-top: 20px;
`;
export const InputsDiv = styled.div`
  width: 30%;
`;
export const LabelInput = styled(LabelTextGlobal)`
  font-size: 16px;
  grid-template-columns: 85px 1fr;
`;
export const InputText = styled.input`
  border: 1px solid #b293df;
  border-radius: 5px;
  padding: 0 12px;
  height: 45px;
  width: 180px;
  box-sizing: border-box;
  // margin-left: 30px;
  margin-left: -2px;
`;
export const Select = styled(SelectGlobal)`
  color: #5e5e5e;
  width: 200px;
`;
export const InputsDiv1 = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
`;
export const LabelText = styled(LabelTextGlobal)`
  margin-right: 5px;
  display: flex;
  align-items: flex-start;
  font-weight: 700;
  height: 100px;
  margin-bottom: 0;
  font-size: 16px;
`;
export const TextArea = styled(TextAreaGlobal)`
  height: 100px;
  width: 350px;
`;
export const SubmitButton = styled.button`
  background-color: #5e90a2;
  color: white;
  border: none;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  margin-left: 80px;
`;
export const ContainerValor = styled.div`
  margin-top: 70px;
  width: 100%;
`;
export const ValorTitle = styled(TitleValorGlobal)``;
export const ListArea = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 40vh;
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e9ec;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #2f8055;
    border-radius: 10px;
  }
`;
export const Card = styled.div`
  display: grid;
  grid-template-columns: 55% 45%;
  background-color: #f6f6f6;
  width: 95%;
  height: 70px;
  margin-top: 20px;
  position: relative;
`;
export const CardItem = styled.p`
  font-size: 14px;
  height: 100%;
  padding-left: 15px;
  display: flex;
  align-items: center;
  color: #5e5e5e;
  strong {
    margin-right: 5px;
  }
`;
export const CardItem1 = styled(CardItem)`
  grid-column: span 2;
`;
export const TrashButton = styled.button`
  position: absolute;
  border: none;
  background-color: transparent;
  font-size: 20px;
  height: 20px;
  right: 5px;
  top: 25px;
  color: inherit;
  color: #5e5e5e;
`;
