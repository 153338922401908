import { FaCheckDouble } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { CheckIcon, CloseButton, Container, Title } from "./_modalsucesso";

interface Props {
  content: string;
  setState: any;
  state: boolean;
  link?: string;
}

export const ModalSucesso = ({ content, state, setState, link }: Props) => {
  const navigate = useNavigate();
  const timer = useRef<number>();

  const handleClose = () => {
    clearTimeout(timer.current);
    if (link !== undefined) {
      navigate(`${link}`);
    } else {
      setState(false);
    }
  };

  useEffect(() => {
    if (state) {
      timer.current = window.setTimeout(() => {
        handleClose();
      }, 5000);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [state]);

  return (
    <Modal
      isOpen={state}
      onRequestClose={handleClose}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "15px",
          border: "0",
        },
        overlay: {
          background: "#0005",
        },
      }}
    >
      <Container>
        <CloseButton onClick={handleClose}>
          <AiOutlineClose />
        </CloseButton>
        <Title>{content}</Title>
        <CheckIcon>
          <FaCheckDouble />
        </CheckIcon>
      </Container>
    </Modal>
  );
};
