import { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
    :root{
        --purple: #986FD5;
        --gray:   #707070;
        --gray02: #9E9E9E;
        --blue:   #5C70A0;
        --green:  #32948C;
        --white:  #F3F3F3;

        --fontRoboto: 'Roboto';
        --fontPoppins: 'Poppins';
    }

    *{
        margin: 0;
        padding: 0;
        font-family: "poppins", sans-serif;
    }
    *:focus{
      outline: none;
    }

    body{
        -webkit-font-smoothing: antialiased;
    }

    html {
        @media (max-width: 1080px) {
            font-size: 93.75%;
        }
        @media (max-width: 720px) {
            font-size: 87.5%;
        }
    }

    button{
        cursor: pointer;
    }

    [disabled]{
        opacity: 0.6;
        cursor: not-allowed;
    }

    @keyframes rendering{
        from{
            opacity:0
        }
        to{
            opacity:1
        }
    }
`;
