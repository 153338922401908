import styled from "styled-components";
import {
  ContainerValorGlobal,
  InputTextGlobal,
} from "../../shared/PageStyles/_pagestyles";

export const InputText = styled(InputTextGlobal)`
  padding-right: 18.5px;
  border: 2px solid #986fd5;
`;
export const ContainerValor = styled(ContainerValorGlobal)``;
export const ContainerCardList = styled.div`
  padding-right: 3%;
  overflow-y: scroll;
  height: 50vh;
  max-height: 500px;
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e9ec;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #2f8055;
    border-radius: 10px;
  }
`;
export const ValorCard = styled.div`
  background-color: #f6f6f6;
  display: grid;
  grid-template-columns: 1fr 30px;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 15px;
  height: 60px;
`;
export const ValorCardInfos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-left: 10px;
`;
export const ValorCardElement = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #7a7a7a;
  display: flex;
  align-items: center;
`;
export const ValorCardElement1 = styled(ValorCardElement)`
  grid-column: span 3;
  font-weight: 400;
`;
export const ValorCardIcons = styled.div`
  display: flex;
  flex-direction: column;
`;
export const BtnIcon = styled.button`
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #5e5e5e;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SearchArea = styled.div`
  display: flex;
  justify-content: end;
  width: 96%;
  margin-bottom: 20px;
`;
export const SearchContainer = styled.div`
  width: 250px;
  height: 35px;
  position: relative;
`;
export const SearchButton = styled.button`
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translate(0, -50%);
  background-color: #fff;
  border: none;
  height: 80%;
  width: 30px;
`;
