import { Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { BsArrowLeft, BsFillTrashFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { ModalSucesso } from "../../shared/ModalSucesso/ModalSucesso";
import PageLayout from "../../shared/PageLayout/PageLayout";
import Title from "../../shared/Title/Title";
import { messageError, messageSuccess } from "../../utils/message";
import {
  BackButton,
  ButtonsContainer,
  ContainerValor,
  CreateButton,
  Form,
  IconButton,
  IconsCell,
  SubmitArea,
  SubmitButton,
  Table,
  TableCell,
  TableCellCheckbox,
  TableCellInput,
  TableContainer,
  TableHeader,
} from "./_cadastrarchecklistsperguntas";

interface IQuestion {
  id: string;
  name: string;
  recommended_action: boolean;
  required: boolean;

  isNew?: boolean;
}

const CadastrarChecklistsPerguntas = () => {
  // usestate para controlar o modal
  const [excluido, setExcluido] = useState(false);

  const { sectionId } = useParams<{
    sectionId?: string;
  }>();

  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const navigate = useNavigate();

  const getQuestions = async () => {
    try {
      const response = await api.get(`/task/${sectionId}`);
      setQuestions(response.data);
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    getQuestions();
  }, [sectionId]);

  const handleAdd = async () => {
    const newQuestion = questions.find((q) => q.isNew);

    const blankQuestion = {
      id: Math.random().toString(),
      name: "",
      isNew: true,
      recommended_action: true,
      required: true,
    };

    if (newQuestion) {
      if (!newQuestion.name) {
        return;
      }

      const question = (await postQuestion(newQuestion)) as IQuestion;

      setQuestions((questions) => {
        const nonNewQuestions = questions.filter((q) => !q.isNew);
        return [...nonNewQuestions, question, blankQuestion];
      });
    } else {
      setQuestions((questions) => [...questions, blankQuestion]);
    }
  };

  const handleChangeName = (value: string, id: string) => {
    setQuestions((prev) =>
      prev.map((q) =>
        q.id === id
          ? {
              ...q,
              name: value,
            }
          : q
      )
    );
  };

  const handleDelete = async (id: string, isNew?: boolean) => {
    if (isNew) {
      setQuestions((prev) => prev.filter((question) => question.id !== id));
      return;
    }

    try {
      await api.delete(`/task/${id}`);

      setQuestions((prev) => prev.filter((question) => question.id !== id));
      setExcluido(true);
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newQuestion = questions.find((q) => q.isNew);

    if (newQuestion) {
      await postQuestion(newQuestion);
    }

    messageSuccess("Perguntas salvas com sucesso!");
    navigate(-1);
  };

  const postQuestion = async (question: IQuestion) => {
    try {
      const response = await api.post(`/task/${sectionId}`, {
        name: question.name,
        required: question.required,
        recommended_action: question.recommended_action,
      });

      return response.data;
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const updateQuestion = async (question: IQuestion) => {
    try {
      const response = await api.put(`/task/${question.id}`, {
        name: question.name,
        required: question.required,
        recommended_action: question.recommended_action,
      });

      return response.data;
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const handleToggleRequired = async (question: IQuestion) => {
    const updatedQuestion = await updateQuestion({
      ...question,
      required: !question.required,
    });

    setQuestions((prev) =>
      prev.map((q) => (q.id === question.id ? updatedQuestion : q))
    );
  };

  const handleToggleRecommendedAction = async (question: IQuestion) => {
    const updatedQuestion = await updateQuestion({
      ...question,
      recommended_action: !question.recommended_action,
    });

    setQuestions((prev) =>
      prev.map((q) => (q.id === question.id ? updatedQuestion : q))
    );
  };

  return (
    <PageLayout>
      <Title
        titulo="Cadastrar Checklists > Perguntas"
        subtitulo="Atribua as perguntas para a seção"
      />
      <ButtonsContainer>
        <BackButton type="button" onClick={() => navigate(-1)}>
          <BsArrowLeft /> Voltar
        </BackButton>
        <CreateButton type="button" onClick={handleAdd}>
          Criar nova pergunta
        </CreateButton>
      </ButtonsContainer>
      <Form onSubmit={handleSubmit}>
        <ContainerValor>
          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <TableHeader>Pergunta</TableHeader>
                  <TableHeader>Obrigatório</TableHeader>
                  <TableHeader>Exige ação recomendada</TableHeader>
                  <th />
                </tr>
              </thead>
              <tbody>
                {questions.map((question, index) => (
                  <tr key={question.id}>
                    <TableCell>
                      {question.isNew ? (
                        <TableCellInput
                          placeholder="Digite a nova pergunta..."
                          ref={(el) => {
                            // Focar input quando clicar em criar nova pergunta
                            if (el) {
                              el.focus();
                            }
                          }}
                          required
                          value={question.name}
                          onChange={(e) =>
                            handleChangeName(e.target.value, question.id)
                          }
                        />
                      ) : (
                        question.name
                      )}
                    </TableCell>
                    <TableCellCheckbox>
                      <Checkbox
                        checked={question.required}
                        sx={{
                          color: "#986FD5",
                          "&.Mui-checked": {
                            color: "#986FD5",
                          },
                        }}
                        onChange={() => {
                          handleToggleRequired(question);
                        }}
                      />
                    </TableCellCheckbox>
                    <TableCellCheckbox>
                      <Checkbox
                        checked={question.recommended_action}
                        onChange={() => {
                          handleToggleRecommendedAction(question);
                        }}
                        sx={{
                          color: "#986FD5",
                          "&.Mui-checked": {
                            color: "#986FD5",
                          },
                        }}
                      />
                    </TableCellCheckbox>
                    <IconsCell>
                      <IconButton
                        type="button"
                        onClick={() =>
                          handleDelete(question.id, question.isNew)
                        }
                      >
                        <BsFillTrashFill />
                      </IconButton>
                    </IconsCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </ContainerValor>
        <SubmitArea>
          <SubmitButton>Salvar</SubmitButton>
        </SubmitArea>
      </Form>
      <ModalSucesso
        content="Excluído com sucesso!"
        setState={setExcluido}
        state={excluido}
      />
    </PageLayout>
  );
};
export default CadastrarChecklistsPerguntas;
