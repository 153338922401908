import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useAuth } from "../../hooks/useAuth";
import api from "../../services/api";
import { ModalSucesso } from "../../shared/ModalSucesso/ModalSucesso";
import PageLayout from "../../shared/PageLayout/PageLayout";
import Title from "../../shared/Title/Title";
import { messageError } from "../../utils/message";
import {
  ButtonFileDelete,
  ContainerInput,
  FileAddButton,
  FileLine,
  InputDiv,
  InputFile,
  InputFileContainer,
  InputText,
  LabelInput,
  SubmitArea,
  SubmitButton,
} from "./_editardadosacesso";

const EditarDadosAcesso = () => {
  const { user } = useAuth();

  // usestate para controlar o modal
  const [alterado, setAlterado] = useState(false);
  const [photo, setPhoto] = useState<File>();
  const [email, setEmail] = useState(user?.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setPhoto(e.target.files[0]);
    }
  };

  const updateEmail = async () => {
    await api.put(`/user/employee/${user?.id}`, {
      email,
    });
  };

  const updatePassword = async () => {
    if (password !== confirmPassword) {
      throw new Error("As senhas não conferem");
    }

    await api.post(`/user/password/change/${user?.id}`, {
      old_password: oldPassword,
      new_password: password,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (photo) {
        const formData = new FormData();

        formData.set("avatar", photo);

        await api.patch(`/user/${user.id}/avatar`, formData);
      }

      if (email && email !== user?.email) {
        await updateEmail();
      }

      if (password) {
        await updatePassword();
      }

      setAlterado(true);
    } catch (error: any) {
      messageError(
        error?.response?.data?.message ||
          error?.message ||
          "Algo deu errado, tente novamente mais tarde"
      );
    }
  };

  return (
    <PageLayout>
      <Title
        titulo="Editar Dados de Acesso"
        subtitulo="Editar preferências de acesso"
      />
      <ContainerInput onSubmit={handleSubmit}>
        <InputDiv>
          <LabelInput>
            Email:
            <InputText
              type="text"
              placeholder="Digite"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </LabelInput>
          <LabelInput>
            Antiga Senha:
            <InputText
              type="password"
              placeholder="Digite"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </LabelInput>
          <LabelInput>
            Nova Senha:
            <InputText
              type="password"
              placeholder="Digite"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </LabelInput>
          <LabelInput>
            Confirme a Senha:
            <InputText
              type="password"
              placeholder="Digite"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </LabelInput>
          <FileLine>
            Foto:
            <InputFileContainer>
              <InputFile
                type="file"
                placeholder="Digite"
                id="avatar-input"
                onChange={handleFileChange}
                accept="image/*"
              />
              <ButtonFileDelete
                type="button"
                onClick={() => {
                  setPhoto(undefined);
                }}
              >
                <IoMdClose />
              </ButtonFileDelete>
            </InputFileContainer>
            <FileAddButton as="label" htmlFor="avatar-input">
              Adicionar arquivo
            </FileAddButton>
          </FileLine>
        </InputDiv>
        <SubmitArea>
          <SubmitButton type="submit">Salvar</SubmitButton>
        </SubmitArea>
      </ContainerInput>
      <ModalSucesso
        content="Alterado com sucesso!"
        setState={setAlterado}
        state={alterado}
      />
    </PageLayout>
  );
};
export default EditarDadosAcesso;
