import { useEffect, useState } from "react";
import { BsPenFill, BsSearch, BsTrashFill } from "react-icons/bs";
import api from "../../services/api";
import { ModalSucesso } from "../../shared/ModalSucesso/ModalSucesso";
import PageLayout from "../../shared/PageLayout/PageLayout";
import Title from "../../shared/Title/Title";
import { maskPhone } from "../../utils/mask";
import { messageError } from "../../utils/message";
import ModalEditar from "./components/ModalEditar";
import {
  BtnIcon,
  ContainerCardList,
  ContainerValor,
  InputText,
  ValorCard,
  ValorCardIcons,
  ValorCardInfos,
  ValorCardElement,
  ValorCardElement1,
  SearchArea,
  SearchContainer,
  SearchButton,
} from "./_cadastroempresas";

export interface IAddress {
  id: string;
  zip: string;
  street: string;
  number: string;
  district: string;
  city: string;
  uf: string;
  user_id: string | null;
  company_id: string;
  created_at: string;
  updated_at: string;
}

export interface ICompany {
  id: string;
  name: string;
  email: string;
  phone: string;
  cnpj: string;
  address: IAddress;
  user_id: string;
  created_at: string;
  updated_at: string;
}

const CadastroEmpresas = () => {
  // usestate para controlar o modal
  const [alterado, setAlterado] = useState(false);
  const [excluido, setExcluido] = useState(false);
  const [editar, setEditar] = useState(false);
  const [cnpjFilter, setCnpjFilter] = useState("");
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<ICompany>();

  const getCompanies = async () => {
    try {
      const response = await api.get<ICompany[]>("/company");

      setCompanies(response.data);
    } catch (error: any) {
      messageError(
        error?.response?.data?.message ||
          error?.message ||
          "Algo deu errado, tente novamente mais tarde"
      );
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const handleOpenEditarModal = (company: ICompany) => {
    setSelectedCompany(company);
    setEditar(true);
  };

  const handleDelete = async (id: string) => {
    try {
      await api.delete(`/company/${id}`);

      setCompanies((prevState) => prevState.filter((c) => c.id !== id));

      setExcluido(true);
    } catch (error: any) {
      messageError(
        error?.response?.data?.message ||
          error?.message ||
          "Algo deu errado, tente novamente mais tarde"
      );
    }
  };

  const handleSuccessfulUpdate = (company: ICompany) => {
    const { id } = company;

    setCompanies((prevState) =>
      prevState.map((c) => (c.id === id ? company : c))
    );
    setAlterado(true);
  };

  const filterCompany = (company: ICompany): boolean => {
    if (!cnpjFilter) {
      return true;
    }

    const normalized = company.cnpj.replace(/\D/g, "");
    const normalizedFilter = cnpjFilter.replace(/\D/g, "");

    return normalized.includes(normalizedFilter);
  };

  const filteredCompanies = companies.filter(filterCompany);

  return (
    <PageLayout>
      <Title
        titulo="Cadastro de Empresas"
        subtitulo="Gerenciamento de empresas clientes"
      />
      <ContainerValor>
        <SearchArea>
          <SearchContainer>
            <InputText
              type="search"
              placeholder="Buscar por CNPJ"
              value={cnpjFilter}
              onChange={(e) => setCnpjFilter(e.target.value)}
            />
            <SearchButton>
              <BsSearch />
            </SearchButton>
          </SearchContainer>
        </SearchArea>
        <ContainerCardList>
          {filteredCompanies.map((company) => (
            <ValorCard key={company.id}>
              <ValorCardInfos>
                <ValorCardElement>{company.name}</ValorCardElement>
                <ValorCardElement>{maskPhone(company.phone)}</ValorCardElement>
                <ValorCardElement>{company.email}</ValorCardElement>
                <ValorCardElement1>
                  {company?.address?.street}, {company?.address?.number} -{" "}
                  {company?.address?.district} - {company?.address?.city} -
                  {company?.address?.uf}
                </ValorCardElement1>
              </ValorCardInfos>
              <ValorCardIcons>
                <BtnIcon
                  type="button"
                  onClick={() => handleOpenEditarModal(company)}
                >
                  <BsPenFill />
                </BtnIcon>
                <BtnIcon type="button" onClick={() => handleDelete(company.id)}>
                  <BsTrashFill />
                </BtnIcon>
              </ValorCardIcons>
            </ValorCard>
          ))}
        </ContainerCardList>
      </ContainerValor>
      <ModalSucesso
        content="Alterado com sucesso!"
        setState={setAlterado}
        state={alterado}
      />
      <ModalSucesso
        content="Excluído com sucesso!"
        setState={setExcluido}
        state={excluido}
      />
      {selectedCompany && (
        <ModalEditar
          company={selectedCompany}
          state={editar}
          setState={setEditar}
          onSuccessfulUpdate={handleSuccessfulUpdate}
          key={selectedCompany.id}
        />
      )}
    </PageLayout>
  );
};
export default CadastroEmpresas;
