import styled from "styled-components";
import {
  ContainerValorGlobal,
  InputTextGlobal,
  LabelTextGlobal,
  SubmitAreaGlobal,
  SubmitButtonGlobal,
  ValorTitleGlobal,
} from "../../shared/PageStyles/_pagestyles";

export const ContainerInput = styled.form`
  margin-top: 20px;
`;
export const InputDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
`;
export const InputText = styled(InputTextGlobal)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const LabelAdicional = styled.p`
  font-weight: 400;
  color: #c1c0c0;
`;
export const LabelInput = styled(LabelTextGlobal)``;
export const LabelInput1 = styled(LabelTextGlobal)`
  grid-template-columns: 120px 1fr 50px;
`;
export const SubmitArea = styled(SubmitAreaGlobal)``;
export const SubmitButton = styled(SubmitButtonGlobal)``;
export const ContainerValor = styled(ContainerValorGlobal)``;
export const ContainerCardList = styled.div`
  padding-right: 3%;
  overflow-y: scroll;
  height: 40vh;
  max-height: 350px;
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e9ec;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #2f8055;
    border-radius: 10px;
  }
`;
export const ValorTitle = styled(ValorTitleGlobal)``;
export const ValorCard = styled.div`
  background-color: #f6f6f6;
  display: grid;
  grid-template-columns: 1fr 30px;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 60px;
`;
export const ValorCardInfos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-left: 10px;
`;
export const ValorCardLine = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #7a7a7a;
  display: flex;
  align-items: center;
`;
export const ValorCardIcons = styled.div`
  display: flex;
  flex-direction: column;
`;
export const BtnIcon = styled.button`
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #5e5e5e;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
