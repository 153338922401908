import { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import api from "../../services/api";
import { ModalSucesso } from "../../shared/ModalSucesso/ModalSucesso";
import PageLayout from "../../shared/PageLayout/PageLayout";
import Title from "../../shared/Title/Title";
import { messageError } from "../../utils/message";
import {
  ContainerInput,
  ContainerValor,
  InputText,
  LabelInput,
  SubArea,
  SubmitButton,
  Table,
  TableCell2,
  TableCellButton,
  TableContainer,
  TableLine,
  TitleValor,
  TrashButton,
} from "./_cadastrareventosseguranca";

interface ISecurityEvent {
  id: string;
  event_type: string;
  created_at: string;
  updated_at: string;
}

const CadastrarEventosSeguranca = () => {
  const [incluido, setIncluido] = useState(false);
  const [excluido, setExcluido] = useState(false);
  const [securityEvents, setSecurityEvents] = useState<ISecurityEvent[]>([]);
  const [eventType, setEventType] = useState("");

  useEffect(() => {
    fetchSecurityEvents();
  }, []);

  const fetchSecurityEvents = async () => {
    try {
      const { data } = await api.get<ISecurityEvent[]>("/security-event");
      setSecurityEvents(data);
    } catch (err: any) {
      messageError(
        err?.response?.data?.message ||
          err?.message ||
          "Algo deu errado, tente novamente mais tarde!"
      );
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await api.post<ISecurityEvent>("/security-event", {
        event_type: eventType,
      });

      setIncluido(true);
      setEventType("");

      setSecurityEvents((prevState) => [...prevState, response.data]);
    } catch (err: any) {
      messageError(
        err?.response?.data?.message ||
          err?.message ||
          "Algo deu errado, tente novamente mais tarde!"
      );
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await api.delete(`/security-event/${id}`);

      setExcluido(true);
      setSecurityEvents((prevState) =>
        prevState.filter((event) => event.id !== id)
      );
    } catch (err: any) {
      messageError(
        err?.response?.data?.message ||
          err?.message ||
          "Algo deu errado, tente novamente mais tarde!"
      );
    }
  };

  return (
    <PageLayout>
      <Title titulo="Cadastrar Segurança" subtitulo="Cadastrar Segurança" />
      <ContainerInput onSubmit={handleSubmit}>
        <LabelInput>
          Tipo de Evento:
          {/* <Select defaultValue="">
                    <option value="" hidden disabled>
                        Selecione
                    </option>
                    <option value="N° de funcionários (pico)">
                        N° de funcionários (pico)
                    </option>
                    <option value="N° de horas homem trabalhadas">
                        N° de horas homem trabalhadas
                    </option>
                    <option value="N° de acidentes com afastamento > 15 dias">
                        N° de acidentes com afastamento {">"} 15 dias
                    </option>
                    <option value="N° de acidentes com afastamento < 15 dias">
                        N° de acidentes com afastamento {"<"} 15 dias
                    </option>
                    <option value="N° de acidentes sem afastamento">
                        N° de acidentes sem afastamento
                    </option>
                    <option value="N° horas de treinamento de segurança">
                        N° horas de treinamento de segurança
                    </option>
                    </Select> */}
          <InputText
            name="name"
            value={eventType}
            onChange={(e) => setEventType(e.target.value)}
            required
            placeholder="Digite"
          />
        </LabelInput>
        <SubArea>
          <SubmitButton type="submit" value="Cadastrar Segurança" />
        </SubArea>
      </ContainerInput>
      <ContainerValor>
        <TitleValor>Eventos de Segurança</TitleValor>
        <TableContainer>
          <Table>
            <tbody>
              {securityEvents.map((item) => (
                <TableLine key={item.id}>
                  <TableCell2>{item.event_type}</TableCell2>
                  <TableCellButton>
                    <TrashButton
                      type="button"
                      onClick={() => handleDelete(item.id)}
                    >
                      <AiFillDelete />
                    </TrashButton>
                  </TableCellButton>
                </TableLine>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </ContainerValor>
      <ModalSucesso
        content="Incluido com sucesso!"
        state={incluido}
        setState={setIncluido}
      />
      <ModalSucesso
        content="Excluido com sucesso!"
        state={excluido}
        setState={setExcluido}
      />
    </PageLayout>
  );
};
export default CadastrarEventosSeguranca;
