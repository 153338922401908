import { yupResolver } from "@hookform/resolvers/yup";
import { SetStateAction } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import { InferType } from "yup";
import api from "../../../services/api";
import MaskedInput from "../../../shared/MaskedInput/MaskedInput";
import { maskCnpj, maskPhone } from "../../../utils/mask";
import { messageError } from "../../../utils/message";
import { editCompanySchema } from "../../../validations/CompanySchema";
import { ICompany } from "../CadastroEmpresas";
import {
  Bar,
  CloseBtn,
  Container,
  ContainerInput,
  Header,
  InputText,
  LabelInput,
  LabelInput1,
  LabelInput2,
  LabelInput2Columns,
  LabelLine,
  LabelLine1,
  SubmitArea,
  SubmitButton,
} from "./_modaleditar";

interface Props {
  setState: React.Dispatch<SetStateAction<boolean>>;
  state: boolean;
  company: ICompany;
  onSuccessfulUpdate: (company: ICompany) => void;
}

type EditCompanyFormData = InferType<typeof editCompanySchema>;

const getInitialFormData = (company: ICompany): EditCompanyFormData => {
  const { name, email, phone, cnpj, address } = company;
  const {
    zip = "",
    street = "",
    district = "",
    city = "",
    uf = "",
    number = "",
  } = address ?? {};

  return {
    phone: maskPhone(phone),
    cnpj: maskCnpj(cnpj),
    email,
    name,
    address: {
      uf,
      zip,
      street,
      district,
      city,
      number,
    },
  };
};

const ModalEditar = ({
  company,
  state,
  onSuccessfulUpdate,
  setState,
}: Props) => {
  const { register, handleSubmit } = useForm<EditCompanyFormData>({
    resolver: yupResolver(editCompanySchema),
    defaultValues: getInitialFormData(company),
  });

  const handleClose = () => {
    setState(false);
  };

  const onSubmit: SubmitHandler<EditCompanyFormData> = async (formData) => {
    try {
      const response = await api.put<ICompany>(
        `/company/${company.id}`,
        formData
      );

      onSuccessfulUpdate(response.data);
      handleClose();
    } catch (error: any) {
      messageError(error?.response?.data?.message || error.message);
    }
  };

  return (
    <Modal
      isOpen={state}
      onRequestClose={handleClose}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          border: "0",
          padding: "0",
        },
        overlay: {
          background: "#0005",
        },
      }}
    >
      <Container>
        <Header>
          Editar cadastro de empresa
          <CloseBtn type="button" onClick={handleClose}>
            <AiOutlineClose />
          </CloseBtn>
        </Header>
        <ContainerInput onSubmit={handleSubmit(onSubmit)}>
          <LabelLine>
            <LabelInput>
              Nome da Empresa:
              <InputText
                type="text"
                placeholder="Ex.: Oficina ABC"
                {...register("name")}
              />
            </LabelInput>
            <LabelInput>
              Telefone:
              <InputText
                type="tel"
                as={MaskedInput}
                maskFunction={maskPhone}
                placeholder="Ex.: (xx) x xxxx-xxxx"
                {...register("phone")}
              />
            </LabelInput>
          </LabelLine>
          <Bar />
          <LabelLine>
            <LabelInput>
              E-mail:
              <InputText
                type="email"
                placeholder="Ex.: email@email.com"
                {...register("email")}
              />
            </LabelInput>
            <LabelInput>
              CNPJ:
              <InputText
                as={MaskedInput}
                maskFunction={maskCnpj}
                type="text"
                placeholder="Ex.: 00.000.000/0000-00"
                {...register("cnpj")}
              />
            </LabelInput>
          </LabelLine>
          <Bar />
          <LabelLine1>
            <LabelInput2>
              CEP:
              <InputText
                type="text"
                placeholder="Ex.: 22111-333"
                {...register("address.zip")}
              />
            </LabelInput2>
            <LabelInput2Columns>
              Endereço:
              <InputText
                type="text"
                placeholder="Ex.: Rua ABC, 100 - SL 400"
                {...register("address.street")}
              />
            </LabelInput2Columns>
          </LabelLine1>
          <LabelLine1>
            <LabelInput2>
              Número:
              <InputText
                type="text"
                placeholder="Ex.: 22111-333"
                {...register("address.number")}
              />
            </LabelInput2>
          </LabelLine1>
          <LabelLine1>
            <LabelInput2>
              Bairro:
              <InputText
                type="text"
                placeholder="Ex.: Bairro Santa Bárbara"
                {...register("address.district")}
              />
            </LabelInput2>
            <LabelInput2>
              Cidade:
              <InputText
                type="text"
                placeholder="Ex.: São Paulo"
                {...register("address.city")}
              />
            </LabelInput2>
            <LabelInput1>
              UF:
              <InputText
                type="text"
                placeholder="Ex.: SP"
                {...register("address.uf")}
              />
            </LabelInput1>
          </LabelLine1>

          <SubmitArea>
            <SubmitButton type="submit">Salvar</SubmitButton>
          </SubmitArea>
        </ContainerInput>
      </Container>
    </Modal>
  );
};
export default ModalEditar;
