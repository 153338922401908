import { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import api from "../../services/api";
import { ModalSucesso } from "../../shared/ModalSucesso/ModalSucesso";
import PageLayout from "../../shared/PageLayout/PageLayout";
import Title from "../../shared/Title/Title";
import {
  ContainerInput,
  ContainerValor,
  InputDiv,
  InputText,
  LabelInput,
  SubmitArea,
  SubmitButton,
  Table,
  TableCell,
  TableContainer,
  TitleValor,
  TrashButton,
  TrashCell,
} from "./_cadastrarMotivoMudanca";

interface IChangeReason {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

const CadastrarMotivoMudanca = () => {
  // usestate para controlar o modal
  const [incluido, setIncluido] = useState(false);
  const [excluido, setExcluido] = useState(false);
  const [name, setName] = useState("");
  const [changeReasons, setChangeReasons] = useState<IChangeReason[]>([
    {
      id: "1",
      name: "teste",
      created_at: "2021-08-10T18:00:00.000Z",
      updated_at: "2021-08-10T18:00:00.000Z",
    },
  ]);

  /* const getData = async () => {
    try {
      const response = await api.get<IChangeReason[]>("/unit");
      setChangeReasons(response.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []); */

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setChangeReasons((prevState) => [
      ...prevState,
      {
        id: `${changeReasons.length + 1}`,
        name,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      },
    ]);
    setName("");
    setIncluido(true);

    /* try {
      const response = await api.post<IChangeReason>("/unit", {
        name,
      });
      
      setChangeReasons([...changeReasons, response.data]);

      setIncluido(true);
    } catch (err: any) {
      console.log(err);
    } */
  };

  const handleDelete = async (id: string) => {
    setChangeReasons(changeReasons.filter((unit) => unit.id !== id));
    setExcluido(true);
    /* try {
      await api.delete(`/unit/${id}`);

    } catch (err: any) {
      console.log(err);
    } */
  };

  return (
    <PageLayout>
      <Title
        titulo="Cadastrar Motivos de Mudanças e Alterações"
        subtitulo="Criar novos motivos de mudanças e alterações"
      />
      <ContainerInput onSubmit={handleSubmit}>
        <InputDiv>
          <LabelInput>
            Motivo:
            <InputText
              type="text"
              placeholder="Digite"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </LabelInput>
        </InputDiv>
        <SubmitArea>
          <SubmitButton type="submit">Cadastrar Motivo</SubmitButton>
        </SubmitArea>
      </ContainerInput>
      <TitleValor>Motivos de Mudanças e Alterações</TitleValor>
      <ContainerValor>
        <TableContainer>
          <Table>
            <tbody>
              {changeReasons.map((unit) => (
                <tr key={unit.id}>
                  <TableCell>{unit.name}</TableCell>
                  <TrashCell>
                    <TrashButton
                      type="button"
                      onClick={() => handleDelete(unit.id)}
                    >
                      <AiFillDelete />
                    </TrashButton>
                  </TrashCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </ContainerValor>

      <ModalSucesso
        content="Incluída com sucesso!"
        setState={setIncluido}
        state={incluido}
      />
      <ModalSucesso
        content="Excluído com sucesso!"
        setState={setExcluido}
        state={excluido}
      />
    </PageLayout>
  );
};
export default CadastrarMotivoMudanca;
