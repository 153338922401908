export const maskPhone = (value: string) => {
  const onlyDigits = value.replace(/\D/g, "");

  if (onlyDigits.length <= 10) {
    return onlyDigits
      .replace(/(\d{2})(\d)/, "($1)$2")
      .replace(/(\d{4})(\d)/, "$1-$2");
  }

  return onlyDigits
    .replace(/(\d{2})(\d)/, "($1)$2")
    .replace(/(\d{5})(\d)/, "$1-$2");
};

export const maskCnpj = (value: string) => {
  if (!value) {
    return "";
  }

  const onlyDigits = value.replace(/\D/g, "");

  return onlyDigits
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2");
};
