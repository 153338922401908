import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { ModalSucesso } from "../../shared/ModalSucesso/ModalSucesso";
import {
  BackgroundTop,
  Container,
  Form,
  ButtonLogin,
  ButtonCadastro,
  ChangePassword,
  CheckBox,
  LinkCadastro,
  BackgroundBottom,
  Logo,
  InputText,
  ControllerStyle,
  LogoContainer,
  TitleContainer,
  SubtitleContainer,
  TitlesContainer,
} from "./_recuperarsenha";

const backgroundTop = require("../../assets/backgroundTop.png");
const backgroundBottom = require("../../assets/backgroundBottom.png");
const logo = require("../../assets/logo_nobackground.png");
const RecuperarSenha = () => {
  const [enviadaModal, setEnviadaModal] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await api.post("/user/password/forgot", {
        email,
      });
    } catch (error: any) {
      console.log(error);
    } finally {
      setEnviadaModal(true);
    }
  };

  return (
    <ControllerStyle>
      <BackgroundTop>
        <img src={backgroundTop} alt="" />
      </BackgroundTop>
      <LogoContainer>
        <Logo src={logo} />
      </LogoContainer>

      <Container>
        {/* {errors && <ErrorMessage>Erro: {errors}</ErrorMessage>} */}
        <TitlesContainer>
          <TitleContainer>Recuperar senha</TitleContainer>
          <SubtitleContainer>Insira seu e-mail de cadastro</SubtitleContainer>
        </TitlesContainer>
        <Form onSubmit={handleSubmit}>
          <InputText
            type="email"
            placeholder="E-mail cadastrado"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <ButtonLogin type="submit">Recuperar</ButtonLogin>
        </Form>
      </Container>

      <BackgroundBottom>
        <img src={backgroundBottom} alt="" />
      </BackgroundBottom>
      <ModalSucesso
        content="A sua senha foi enviada para seu e-mail."
        state={enviadaModal}
        setState={setEnviadaModal}
        link="/"
      />
    </ControllerStyle>
  );
};
export default RecuperarSenha;
