import styled from "styled-components";
import { InputTextGlobal, LabelTextGlobal, SubmitButtonGlobal } from "../../../shared/PageStyles/_pagestyles";

export const Container = styled.div`
    width: 600px;
    height: 400px;
    background-color: #fff;
`;
export const Header = styled.header`
    background-color: #5C70A0;
    color: #fff;
    height: 60px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
`;
export const CloseBtn = styled.button `
    color: #fff;
    background-color: transparent;
    border: none;
    font-size: 22px;
    height: 24px;
    width: 24px;
`;
export const LabelInput = styled(LabelTextGlobal)``;
export const InputText = styled(InputTextGlobal)`
    border: 1px solid #CBCACA;
`;
export const Bar = styled.div`
    width: 100%;
    height: 1px;
    background-color: #CBCACA;
    margin-bottom: 10px;
`;
export const SubmitButton = styled(SubmitButtonGlobal)``;
export const ContainerInput = styled.form`
    margin: 20px 25px;
`;
export const LabelLine = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
`;
export const SubmitArea = styled.div`
    display: flex;
    justify-content: end;
    align-items: flex-end;
    margin-top: 100px;
`;