import { Subtitulo, Titulo } from "./_title";

interface Props {
    titulo: string;
    subtitulo?: string;
}

const Title = ({titulo, subtitulo}: Props) => {
    return(
        <>
            <Titulo>{titulo}</Titulo>
            <Subtitulo>{subtitulo}</Subtitulo>
        </>
    )
}
export default Title;