import { forwardRef } from "react";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  maskFunction: (value: string) => string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MaskedInput: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { maskFunction, onChange, ...rest },
  ref
) => {
  const handleMask = (event: React.ChangeEvent<HTMLInputElement>) => {
    const masked = maskFunction(event.target.value);
    event.target.value = masked;
    onChange(event);
  };

  return (
    <input
      type="text"
      onChange={(event) => handleMask(event)}
      {...rest}
      ref={ref}
    />
  );
};

export default forwardRef(MaskedInput);
