import { useState } from "react";
import { BsPenFill, BsTrashFill } from "react-icons/bs";
import { ModalSucesso } from "../../shared/ModalSucesso/ModalSucesso";
import PageLayout from "../../shared/PageLayout/PageLayout";
import Title from "../../shared/Title/Title";
import ModalEditar from "./components/ModalEditar";
import {
  BtnIcon,
  ContainerCardList,
  ContainerInput,
  ContainerValor,
  InputDiv,
  InputText,
  LabelAdicional,
  LabelInput,
  LabelInput1,
  SubmitArea,
  SubmitButton,
  ValorCard,
  ValorCardIcons,
  ValorCardInfos,
  ValorCardLine,
  ValorTitle,
} from "./_planospagamentos";

const unformatCurrency = (value: string) => {
  if (!value) return 0;
  const valueFormatted = value
    .replace(",", "")
    .replace(".", "")
    .replace(/\D/g, "");

  return Number(valueFormatted) / 100;
};

const currencyMask = (value: string) => {
  const valueFormatted = unformatCurrency(value);

  const masked = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    useGrouping: true,
  }).format(valueFormatted);
  return masked;
};

const PlanosPagamentos = () => {
  // usestate para controlar o modal
  const [alterado, setAlterado] = useState(false);
  const [incluido, setIncluido] = useState(false);
  const [excluido, setExcluido] = useState(false);
  const [editar, setEditar] = useState(false);

  const [value, setValue] = useState("");

  const handleOpenEditarModal = () => {
    setEditar(true);
  };

  const handleDelete = () => {
    setExcluido(true);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIncluido(true);
  };

  return (
    <PageLayout>
      <Title
        titulo="Planos e Pagamentos"
        subtitulo="Configurar Planos e Pagamentos"
      />
      <ContainerInput onSubmit={handleSubmit}>
        <InputDiv>
          <LabelInput>
            Nome do plano:
            <InputText type="text" placeholder="Ex.: Standard" />
          </LabelInput>
          <LabelInput1>
            Valor:
            <InputText
              type="text"
              placeholder="Ex.: R$ 120,00"
              value={value}
              onChange={(e) => setValue(currencyMask(e.target.value))}
            />
            <LabelAdicional>/ Mês</LabelAdicional>
          </LabelInput1>
          <LabelInput>
            Franquia de Mensagens:
            <InputText type="text" placeholder="Ex.: 200" />
          </LabelInput>
          <LabelInput1>
            Quantidade de Projetos:
            <InputText type="number" placeholder="Ex.: 200" min={0} />
          </LabelInput1>
          <LabelInput>
            Quantidade de Colaboradores:
            <InputText type="number" placeholder="Ex.: 200" min={0} />
          </LabelInput>
        </InputDiv>
        <SubmitArea>
          <SubmitButton type="submit">Incluir</SubmitButton>
        </SubmitArea>
      </ContainerInput>
      <ContainerValor>
        <ValorTitle>Planos Configurados</ValorTitle>
        <ContainerCardList>
          <ValorCard>
            <ValorCardInfos>
              <ValorCardLine>Básico</ValorCardLine>
              <ValorCardLine>R$: 50,00 / mês</ValorCardLine>
              <ValorCardLine>200 Mensagens</ValorCardLine>
              <ValorCardLine>Ativo</ValorCardLine>
              <div />
              <ValorCardLine>200 Colaboradores</ValorCardLine>
              <ValorCardLine>50 Projetos</ValorCardLine>
            </ValorCardInfos>
            <ValorCardIcons>
              <BtnIcon type="button" onClick={handleOpenEditarModal}>
                <BsPenFill />
              </BtnIcon>
              <BtnIcon type="button" onClick={handleDelete}>
                <BsTrashFill />
              </BtnIcon>
            </ValorCardIcons>
          </ValorCard>
          <ValorCard>
            <ValorCardInfos>
              <ValorCardLine>Básico</ValorCardLine>
              <ValorCardLine>R$: 50,00 / mês</ValorCardLine>
              <ValorCardLine>200 Mensagens</ValorCardLine>
              <ValorCardLine>Ativo</ValorCardLine>
              <div />
              <ValorCardLine>200 Colaboradores</ValorCardLine>
              <ValorCardLine>50 Projetos</ValorCardLine>
            </ValorCardInfos>
            <ValorCardIcons>
              <BtnIcon onClick={handleOpenEditarModal}>
                <BsPenFill />
              </BtnIcon>
              <BtnIcon onClick={handleDelete}>
                <BsTrashFill />
              </BtnIcon>
            </ValorCardIcons>
          </ValorCard>
          <ValorCard>
            <ValorCardInfos>
              <ValorCardLine>Básico</ValorCardLine>
              <ValorCardLine>R$: 50,00 / mês</ValorCardLine>
              <ValorCardLine>200 Mensagens</ValorCardLine>
              <ValorCardLine>Ativo</ValorCardLine>
              <div />
              <ValorCardLine>200 Colaboradores</ValorCardLine>
              <ValorCardLine>50 Projetos</ValorCardLine>
            </ValorCardInfos>
            <ValorCardIcons>
              <BtnIcon onClick={handleOpenEditarModal}>
                <BsPenFill />
              </BtnIcon>
              <BtnIcon onClick={handleDelete}>
                <BsTrashFill />
              </BtnIcon>
            </ValorCardIcons>
          </ValorCard>
          <ValorCard>
            <ValorCardInfos>
              <ValorCardLine>Básico</ValorCardLine>
              <ValorCardLine>R$: 50,00 / mês</ValorCardLine>
              <ValorCardLine>200 Mensagens</ValorCardLine>
              <ValorCardLine>Ativo</ValorCardLine>
              <div />
              <ValorCardLine>200 Colaboradores</ValorCardLine>
              <ValorCardLine>50 Projetos</ValorCardLine>
            </ValorCardInfos>
            <ValorCardIcons>
              <BtnIcon onClick={handleOpenEditarModal}>
                <BsPenFill />
              </BtnIcon>
              <BtnIcon onClick={handleDelete}>
                <BsTrashFill />
              </BtnIcon>
            </ValorCardIcons>
          </ValorCard>
        </ContainerCardList>
      </ContainerValor>
      <ModalSucesso
        content="Alterado com sucesso!"
        setState={setAlterado}
        state={alterado}
      />
      <ModalSucesso
        content="Excluído com sucesso!"
        setState={setExcluido}
        state={excluido}
      />
      <ModalSucesso
        content="Incluído com sucesso!"
        setState={setIncluido}
        state={incluido}
      />
      <ModalEditar
        state={editar}
        setState={setEditar}
        setAlterado={setAlterado}
      />
    </PageLayout>
  );
};
export default PlanosPagamentos;
