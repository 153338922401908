import React, { useEffect, useState } from "react";
import { BsPencilSquare, BsSearch, BsTrashFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { ModalSucesso } from "../../shared/ModalSucesso/ModalSucesso";
import PageLayout from "../../shared/PageLayout/PageLayout";
import Title from "../../shared/Title/Title";
import { messageError } from "../../utils/message";
import {
  Bar,
  ContainerInput,
  ContainerValor,
  InputDiv,
  InputText,
  LabelInput,
  SubmitArea,
  SubmitButton,
  Table,
  TableCell,
  TableContainer,
  TableHeader,
  IconButton,
  IconsCell,
  Select,
} from "./_cadastrarchecklistsnovasecao";

interface IChecklist {
  id: string;
  name: string;
  sectionCount: number;
  description: string;
  created_at: string;
}

interface IChecklistOption {
  value: string;
  label: string;
}

interface ISection {
  id: string;
  name: string;
  taskCount: number;
}

const CadastrarChecklistsNovaSecao = () => {
  // usestate para controlar o modal
  const [incluido, setIncluido] = useState(false);
  const [excluido, setExcluido] = useState(false);
  const [checklistOptions, setChecklistOptions] = useState<IChecklistOption[]>(
    []
  );

  const { checklistId } = useParams<{
    checklistId?: string;
  }>();

  const [sections, setSections] = useState<ISection[]>([]);
  const [selectedChecklistId, setSelectedChecklistId] = useState(checklistId);
  const [newSectionName, setNewSectionName] = useState("");
  const navigate = useNavigate();

  const getChecklists = async () => {
    try {
      const response = await api.get<IChecklist[]>("/checklist");
      setChecklistOptions(
        response.data.map((checklist) => ({
          label: checklist.name,
          value: checklist.id,
        }))
      );
      setSelectedChecklistId(checklistId || "");
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    getChecklists();
  }, []);

  const getSections = async () => {
    try {
      const response = await api.get<ISection[]>(
        `/section/${selectedChecklistId}`
      );

      setSections(response.data);
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    if (selectedChecklistId) {
      getSections();
    }
  }, [selectedChecklistId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await api.post(`/section/${selectedChecklistId}`, {
        name: newSectionName,
      });

      getSections();
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await api.delete(`/section/${id}`);

      setSections((prevState) =>
        prevState.filter((section) => section.id !== id)
      );
      setExcluido(true);
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <PageLayout>
      <Title
        titulo="Cadastrar Checklists"
        subtitulo="Novas seções para Checklist"
      />
      <ContainerInput onSubmit={handleSubmit}>
        <InputDiv>
          <LabelInput>
            Nome:
            <Select
              value={selectedChecklistId}
              onChange={(e) => {
                setSelectedChecklistId(e.target.value);
              }}
            >
              {checklistOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </LabelInput>
          <LabelInput>
            Nome da nova seção:
            <InputText
              type="text"
              placeholder="Selecione"
              value={newSectionName}
              onChange={(e) => setNewSectionName(e.target.value)}
            />
          </LabelInput>
        </InputDiv>
        <SubmitArea>
          <SubmitButton type="submit">Adicionar Seção</SubmitButton>
        </SubmitArea>
      </ContainerInput>
      <Bar />
      <ContainerValor>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <TableHeader>Nome da seção</TableHeader>
                <TableHeader>Total de perguntas</TableHeader>
                <TableHeader>Opções</TableHeader>
              </tr>
            </thead>
            <tbody>
              {sections.map((section) => (
                <tr key={section.id}>
                  <TableCell>{section.name}</TableCell>
                  <TableCell>{section.taskCount || 0} perguntas</TableCell>
                  <IconsCell>
                    <IconButton>
                      <BsSearch />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        navigate(`/cadastrarChecklists/perguntas/${section.id}`)
                      }
                    >
                      <BsPencilSquare />
                    </IconButton>
                    <IconButton
                      type="button"
                      onClick={() => handleDelete(section.id)}
                    >
                      <BsTrashFill />
                    </IconButton>
                  </IconsCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </ContainerValor>
      <ModalSucesso
        content="Incluída com sucesso!"
        setState={setIncluido}
        state={incluido}
      />
      <ModalSucesso
        content="Excluído com sucesso!"
        setState={setExcluido}
        state={excluido}
      />
    </PageLayout>
  );
};
export default CadastrarChecklistsNovaSecao;
