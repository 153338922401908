import * as yup from "yup";

export const editCompanySchema = yup.object({
  name: yup.string().required("Nome é obrigatório"),
  phone: yup.string().required("Telefone é obrigatório"),
  email: yup.string().email("E-mail inválido").required("E-mail obrigatório"),
  cnpj: yup.string().required("CNPJ obrigatório"),
  address: yup.object({
    street: yup.string().required("Rua é obrigatória"),
    zip: yup.string().required("CEP é obrigatório"),
    district: yup.string().required("Bairro é obrigatório"),
    city: yup.string().required("Cidade é obrigatória"),
    uf: yup.string().required("UF é obrigatório"),
    number: yup.string().required("Número é obrigatório"),
  }),
});
