import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { ErrorMessage } from "../../shared/PageStyles/_pagestyles";
import {
  BackgroundTop,
  Container,
  Form,
  ButtonLogin,
  ChangePassword,
  CheckBox,
  BackgroundBottom,
  Logo,
  InputText,
  ControllerStyle,
  LogoContainer,
} from "./_login";

const backgroundTop = require("../../assets/backgroundTop.png");
const backgroundBottom = require("../../assets/backgroundBottom.png");
const logo = require("../../assets/logo_nobackground.png");
const Login = () => {
  const [timer, setTimer] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      setTimer(true);
    }, 1500);
  }, []);
  const { login } = useAuth();

  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState("");
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await login({
        email,
        password: senha,
        remember_me: rememberMe,
      });
      navigate("/cadastroEmpresas");
    } catch (err: any) {
      setErrors(err.response.data.message);
    }
  };
  return (
    <ControllerStyle timer={timer}>
      <BackgroundTop>
        <img src={backgroundTop} alt="" />
      </BackgroundTop>
      <LogoContainer>
        <Logo src={logo} />
      </LogoContainer>

      <Container>
        {errors && <ErrorMessage>Erro: {errors}</ErrorMessage>}
        <Form onSubmit={handleSubmit}>
          <InputText
            type="text"
            placeholder="E-mail"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputText
            type="password"
            placeholder="Senha"
            required
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />
          <ButtonLogin>Login</ButtonLogin>
        </Form>

        <ChangePassword onClick={() => navigate("/RecuperarSenha")}>
          Esqueci minha senha
        </ChangePassword>

        <CheckBox>
          <input
            type="checkbox"
            id="savedValue"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <label htmlFor="savedValue">Me mantenha Logado</label>
        </CheckBox>
      </Container>

      <BackgroundBottom>
        <img src={backgroundBottom} alt="" />
      </BackgroundBottom>
    </ControllerStyle>
  );
};
export default Login;
