import React from "react";
import AuthProvider from "./hooks/useAuth";
import AppRoutes from "./routes/AppRoutes";
import { GlobalStyle } from "./style/global";

function App() {
  return (
    <AuthProvider>
      <GlobalStyle />
      <AppRoutes />
    </AuthProvider>
  );
}

export default App;
