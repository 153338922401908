import { useEffect, useState } from "react";
import { BsPencilSquare, BsSearch, BsTrashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { ModalSucesso } from "../../shared/ModalSucesso/ModalSucesso";
import PageLayout from "../../shared/PageLayout/PageLayout";
import Title from "../../shared/Title/Title";
import { messageError } from "../../utils/message";
import {
  Bar,
  ContainerInput,
  ContainerValor,
  InputDiv,
  InputText,
  LabelInput,
  SubmitArea,
  SubmitButton,
  Table,
  TableCell,
  TableContainer,
  TableHeader,
  IconButton,
  IconsCell,
} from "./_cadastrarchecklists";

interface Option {
  label: string;
  value: string;
}

interface ISupplyCategory {
  id: string;
  name: string;
}

interface IChecklist {
  id: string;
  name: string;
  sectionCount: number;
  description: string;
  created_at: string;
}

const CadastrarChecklists = () => {
  const navigate = useNavigate();

  // usestate para controlar o modal
  const [incluido, setIncluido] = useState(false);
  const [excluido, setExcluido] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [checklists, setChecklists] = useState<IChecklist[]>([]);

  const [supplyCategories, setSupplyCategories] = useState<Option[]>([]);
  const [supplyCategory, setSupplyCategory] = useState("");

  const getChecklists = async () => {
    try {
      const response = await api.get<IChecklist[]>("/checklist");
      setChecklists(response.data);
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    getChecklists();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await api.post("/checklist", {
        name,
        description: description || undefined,
      });

      setChecklists((prevState) => [...prevState, response.data]);
      setIncluido(true);
      setName("");
      setDescription("");
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const handleRemoveChecklist = async (id: string) => {
    try {
      await api.delete(`/checklist/${id}`);

      setChecklists((prevState) =>
        prevState.filter((checklist) => checklist.id !== id)
      );
      setExcluido(true);
    } catch (error: any) {
      messageError(error?.response?.data?.message || error?.message);
    }
  };

  const formatPluralSections = (sectionsNumber: number) => {
    if (sectionsNumber === 1) {
      return "1 seção";
    } else {
      return `${sectionsNumber || 0} seções`;
    }
  };

  return (
    <PageLayout>
      <Title titulo="Cadastrar Checklists" subtitulo="Criar novos checklists" />
      <ContainerInput onSubmit={handleSubmit}>
        <InputDiv>
          <LabelInput>
            Nome do checklist:
            <InputText
              type="text"
              placeholder="Digite"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </LabelInput>
          <LabelInput>
            Descrição:
            <InputText
              type="text"
              placeholder="Digite"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </LabelInput>
          {/* <LabelText>
            Categoria de Insumo:
            <TextArea
              placeholder="Digite"
              required
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </LabelText> 
          <LabelText>
            Categoria de Insumo:
            <Select
              value={supplyCategory}
              onChange={(e) => setSupplyCategory(e.target.value)}
            >
              <option value="" hidden disabled>
                Selecione
              </option>
              {supplyCategories.map((option) => (
                <option key={option.value}>{option.label}</option>
              ))}
            </Select>
          </LabelText>
          */}
        </InputDiv>
        <SubmitArea>
          <SubmitButton type="submit">Criar Checklist</SubmitButton>
        </SubmitArea>
      </ContainerInput>
      <Bar />
      <ContainerValor>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <TableHeader>Nome do CheckList</TableHeader>
                <TableHeader>Total de seções</TableHeader>
                <TableHeader>Opções</TableHeader>
              </tr>
            </thead>
            <tbody>
              {checklists.map((checklist) => (
                <tr key={checklist.id}>
                  <TableCell>{checklist.name}</TableCell>
                  <TableCell>
                    {formatPluralSections(checklist.sectionCount)}
                  </TableCell>
                  <IconsCell>
                    <IconButton>
                      <BsSearch />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        navigate(
                          `/cadastrarChecklists/novaSecao/${checklist.id}`
                        )
                      }
                    >
                      <BsPencilSquare />
                    </IconButton>
                    <IconButton
                      type="button"
                      onClick={() => handleRemoveChecklist(checklist.id)}
                    >
                      <BsTrashFill />
                    </IconButton>
                  </IconsCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </ContainerValor>
      <ModalSucesso
        content="Incluída com sucesso!"
        setState={setIncluido}
        state={incluido}
      />
      <ModalSucesso
        content="Excluído com sucesso!"
        setState={setExcluido}
        state={excluido}
      />
    </PageLayout>
  );
};
export default CadastrarChecklists;
