import styled from "styled-components";
import { InputTextGlobal, LabelTextGlobal, SelectGlobal, SubmitButtonGlobal, TextAreaGlobal, TitleValorGlobal } from "../../shared/PageStyles/_pagestyles";

export const ContainerInput = styled.form`
  label {
    font-weight: bold;
  }
`;

export const InputsDiv1 = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
`;
export const LabelInput1 = styled(LabelTextGlobal)`
  grid-template-columns: 90px 1fr;
  font-size: 16px;
`;
export const InputText = styled(InputTextGlobal)`
  width: 180px;
  margin-left: 5px;
`;
export const LabelInput2 = styled(LabelTextGlobal)`
  font-size: 16px;
  grid-template-columns: 90px 1fr;
  height: 100px;
  align-items: flex-start;
  margin-bottom: 0;
`;
export const TextArea = styled(TextAreaGlobal)`
  height: 100px;
  width: 350px;
  margin-left: 5px;
`;
export const SubmitButton = styled(SubmitButtonGlobal)`
  font-size: 14px;
  margin-left: 30px;
`;
export const ContainerValor = styled.div`
  margin-top: 70px;
  width: 100%;
`;
export const TitleValor = styled(TitleValorGlobal)``;
export const List = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 35vh;
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e9ec;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #2f8055;
    border-radius: 10px;
  }
`;
export const Card = styled.div`
  display: grid;
  grid-template-columns: 55% 45%;
  background-color: #f6f6f6;
  width: 98%;
  height: 70px;
  margin-top: 20px;
  position: relative;
`;
export const CardItem = styled.p`
  font-size: 14px;
  height: 25px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #5E5E5E;
  strong {
    margin-right: 5px;
  }
`;
export const CardItem1 = styled.p`
  grid-column: span 2;
  font-size: 14px;
  height: 25px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #5E5E5E;
  strong {
    margin-right: 5px;
  }
`;
export const TrashButton = styled.button`
  position: absolute;
  border: none;
  background-color: transparent;
  font-size: 20px;
  height: 20px;
  right: 5px;
  top: 25px;
  color: #5E5E5E;
`;
export const SelectInput = styled(SelectGlobal)`
  width: 180px;
  height: 40px;
  margin-left: 5px;
  color: #5e5e5e;
`;
