import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  width: 300px;
  min-height: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #5c70a0;
  position: relative;
`;
export const Title = styled.h2`
  font-size: 28px;
  text-align: center;
  padding: 0 20px;
  font-family: "poppins", "sans-serif";
`;

export const CheckIcon = styled.div`
  font-size: 100px;
  height: 130px;
`;

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 20px;
  width: 20px;
  height: 20px;
  font-weight: bold;
  position: absolute;
  right: 0px;
  top: 0px;
`;
