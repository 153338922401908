import React, {
  createContext,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import api from "../services/api";

export interface IResponseLogin {
  user: User;
  access_token: string;
  refresh_token: string;
}

export interface User {
  id: string;
  name: string;
  cpf: string;
  email: string;
  role: string;
  avatar: string | null;
  created_at: string;
  updated_at: string;
}

interface LoginData {
  email: string;
  password: string;
  remember_me: boolean;
}

interface IUserProvider {
  user: User;
  setUser: React.Dispatch<SetStateAction<User>>;
  isAuthenticated: () => boolean;
  logout: () => void;
  login: (data: LoginData) => Promise<IResponseLogin>;
}

interface ChildrenProps {
  children: ReactNode;
}

const AuthContext = createContext({} as IUserProvider);

const AuthProvider = ({ children }: ChildrenProps) => {
  const [user, setUser] = useState<User>({} as User);

  useEffect(() => {
    const dataUser = localStorage.getItem("@TaskMaster: user");

    if (dataUser) {
      setUser(JSON.parse(dataUser));
    }
  }, []);

  const isAuthenticated = () => {
    return user.id !== undefined;
  };

  const logout = () => {
    localStorage.removeItem("@TaskMaster: user");
    localStorage.removeItem("@TaskMaster: accessToken");
    localStorage.removeItem("@TaskMaster: refreshToken");
    setUser({} as User);
  };

  const contextLogin = async ({ email, password, remember_me }: LoginData) => {
    const response = await api.post<IResponseLogin>("/user/session", {
      email,
      password,
      remember_me,
    });

    if (response.data.user.role !== "Master") {
      throw new Error("Você não tem permissão para acessar o sistema");
    }

    localStorage.setItem(
      "@TaskMaster: accessToken",
      response.data.access_token
    );
    localStorage.setItem(
      "@TaskMaster: user",
      JSON.stringify(response.data.user)
    );
    localStorage.setItem(
      "@TaskMaster: refreshToken",
      response.data.refresh_token
    );

    setUser((prevState) => ({ ...prevState, ...response.data.user }));
    return response.data;
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ user, setUser, isAuthenticated, logout, login: contextLogin }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
