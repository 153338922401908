import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import { Bar, CloseBtn, Container, ContainerInput, Header, InputText, LabelInput, LabelLine, SubmitArea, SubmitButton } from "./_modaleditar";
interface Props {
    setState: any;
    state: boolean;
    setAlterado: any;
}
const ModalEditar = ({state, setState, setAlterado}: Props) => {
    const handleClose = () => {
        setState(false);
    }
    const handleSubmit = (e: any) => {
        e.preventDefault();
        setAlterado(true);
        handleClose();
    }
    return(
        <Modal
        isOpen={state}
        onRequestClose={handleClose}
        style={{
            content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            border: "0",
            padding: "0"
            },
            overlay: {
            background: "#0005",
            },
        }}
        >
        <Container>
            <Header>
                Editar plano cadastrado
                <CloseBtn onClick={handleClose}>
                    <AiOutlineClose />
                </CloseBtn>
            </Header>
            <ContainerInput onSubmit={handleSubmit}>
                <LabelLine>
                    <LabelInput>
                        Nome do plano:
                        <InputText type="text" placeholder="Ex.: Standard" />
                    </LabelInput>
                    <LabelInput>
                        Valor:
                        <InputText type="text" placeholder="Ex.: R$ 120,00" />
                    </LabelInput>
                </LabelLine>
                <Bar />
                <LabelLine>
                    <LabelInput>
                        Franquia de Mensagens:
                        <InputText type="text" placeholder="Ex.: 200" />
                    </LabelInput>
                    <LabelInput>
                        Quantidade de Projetos:
                        <InputText type="text" placeholder="Ex.: 200" />
                    </LabelInput>
                    <LabelInput>
                        Quantidade de Colaboradores:
                        <InputText type="text" placeholder="Ex.: 200" />
                    </LabelInput>
                </LabelLine>
                <SubmitArea>
                    <SubmitButton type="submit">Salvar</SubmitButton>
                </SubmitArea>
            </ContainerInput>
            
        </Container>
        </Modal>
    )
}
export default ModalEditar;