import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AiFillDelete } from "react-icons/ai";
import api from "../../services/api";
import { ModalSucesso } from "../../shared/ModalSucesso/ModalSucesso";
import PageLayout from "../../shared/PageLayout/PageLayout";
import Title from "../../shared/Title/Title";
import { messageError } from "../../utils/message";
import { LaborScheme } from "../../validations/CadastroScheme";
import {
  Card,
  CardItem,
  CardItem1,
  ContainerInput,
  ContainerValor,
  InputsDiv1,
  InputText,
  LabelInput1,
  LabelInput2,
  List,
  SelectInput,
  SubmitButton,
  TextArea,
  TitleValor,
  TrashButton,
} from "./_cadastrarmaoobra";

interface LaborFormData {
  name: string;
  description: string;
  type: string;
}

interface Labor {
  id: string;
  name: string;
  description: string;
  code: string;
  type: string;
  company_id: string;
  created_at: string;
  updated_at: string;
}

const typeLabels = {
  DIRECT: "Direta",
  INDIRECT: "Indireta",
  "THIRD PARTY": "Terceiros",
} as {
  [key: string]: string;
};

const typeOptions = Object.entries(typeLabels).map(([value, label]) => ({
  value,
  label,
}));

const CadastrarMaodeObra = () => {
  // usestate para controlar o modal
  const [incluido, setIncluido] = useState(false);
  const [excluido, setExcluido] = useState(false);
  const [maoDeObras, setMaoDeObras] = useState<Labor[]>([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await api.get<Labor[]>("/labor");

      setMaoDeObras(response.data);
    } catch (err: any) {
      messageError(
        err?.response?.data?.message ||
          err?.message ||
          "Algo deu errado, tente novamente mais tarde"
      );
    }
  };

  const { register, handleSubmit, reset } = useForm<LaborFormData>({
    resolver: yupResolver(LaborScheme),
  });

  const handleIncluir: SubmitHandler<LaborFormData> = async (formData) => {
    const { description, name, type } = formData;

    try {
      const response = await api.post("/labor", {
        name,
        type,
        description: description || undefined,
      });

      reset();

      setMaoDeObras((prevState) => [...prevState, response.data]);
      setIncluido(true);
    } catch (err: any) {
      messageError(
        err?.response?.data?.message ||
          err?.message ||
          "Algo deu errado, tente novamente mais tarde"
      );
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await api.delete(`/labor/${id}`);

      setMaoDeObras((prevState) => prevState.filter((item) => item.id !== id));

      setExcluido(true);
    } catch (err: any) {
      console.log("ERROR", err.message);
    }
  };

  return (
    <PageLayout>
      <Title
        titulo="Cadastrar Tipos de Mão de Obra"
        subtitulo="Criar novos tipos de mão de obra"
      />
      <ContainerInput onSubmit={handleSubmit(handleIncluir)}>
        <InputsDiv1>
          <LabelInput1>
            Nome:
            <InputText
              type="text"
              placeholder="Digite"
              {...register("name")}
              required
            />
          </LabelInput1>
          <LabelInput1>
            <SelectInput defaultValue="" {...register("type")} required>
              <option value="" disabled hidden>
                Escolha o tipo
              </option>
              {typeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </SelectInput>
          </LabelInput1>
        </InputsDiv1>
        <InputsDiv1>
          <LabelInput2>
            Descrição (opcional):
            <TextArea
              cols={50}
              rows={10}
              placeholder="Digite"
              {...register("description")}
            />
          </LabelInput2>
          <SubmitButton type="submit">Cadastrar Mão de Obra</SubmitButton>
        </InputsDiv1>
      </ContainerInput>
      <ContainerValor>
        <TitleValor>Mão de obra cadastrada</TitleValor>
        <List>
          {!!maoDeObras &&
            maoDeObras.map((item) => (
              <Card key={item.id}>
                <CardItem>
                  <strong>Nome:</strong>
                  {item.name}
                </CardItem>
                <CardItem>
                  <strong>Tipo:</strong>
                  {typeLabels[item.type]}
                </CardItem>
                <CardItem1>
                  <strong>Descrição:</strong>
                  {item.description}
                </CardItem1>
                <TrashButton
                  type="button"
                  onClick={() => handleDelete(item.id)}
                >
                  <AiFillDelete />
                </TrashButton>
              </Card>
            ))}
        </List>
      </ContainerValor>
      <ModalSucesso
        content="Incluída com sucesso!"
        setState={setIncluido}
        state={incluido}
      />
      <ModalSucesso
        content="Excluído com sucesso!"
        setState={setExcluido}
        state={excluido}
      />
    </PageLayout>
  );
};
export default CadastrarMaodeObra;
