import styled from "styled-components";

export const Container = styled.div`
  width: 300px;
  height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  position: relative;
`;
export const PhotoContainer = styled.div`
  width: 160px;
  height: 160px;
  position: relative;
  margin-top: 20px;
`;
export const Photo = styled.img`
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 6px double #797979;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  object-fit: cover;
`;
export const InfoContainer = styled.div`
  margin-top: 30px;
`;
export const UserIconBtn = styled.button`
  border: none;
  background-color: transparent;
  width: 22px;
  height: 22px;
  font-size: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #9e9e9e;
`;
export const UserNameLine = styled.h3`
  color: #8f78b9;
  text-align: center;
  font-size: 16px;
`;
export const RoleLine = styled.h4`
  text-align: center;
  color: #7a7a7a;
  font-size: 12px;
`;
export const EmailLine = styled.h5`
  color: #7a7a7a;
  text-align: center;
  font-size: 10px;
`;
export const LogOutBtn = styled.button`
  font-size: 34px;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: none;
  background-color: transparent;
  bottom: 0;
  right: 0;
  color: #9e9e9e;
`;
